@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

.pagination {
  display: block;
  position: relative;
  padding-top: 6px;
  padding-bottom: 6px;
}
.pagination:before {
  content: "";
  display: table;
}
.pagination:after {
  content: "";
  display: table;
  clear: both;
}
.pagination .pagination-btn {
  display: block;
  position: relative;
  float: left;
  border-top: 0;
  border-bottom: 0;
  color: #006596;
  cursor: pointer;
}
.pagination .pagination-btn span {
  display: block;
  position: relative;
  text-align: center;
  line-height: 0.2rem;
  height: 32px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 32px;
  font-size: 0.16rem;
}
.pagination .pagination-btn span.fa {
  font-size: 0.18rem;
  font-weight: bold;
}
.pagination .pagination-btn.current {
  text-decoration: underline;
}
.pagination .pagination-btn:first-child {
  border-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.pagination .pagination-btn:last-child {
  border-right: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.pagination .pagination-btn:first-child span.fa, .pagination .pagination-btn:last-child span.fa {
  font-size: 0.14rem;
  font-weight: normal;
}
.pagination .pagination-btn:not(.ellipsis):hover {
  transform: scale(1.2);
}
