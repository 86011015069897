@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

.desk-policy .policy-section {
  margin-top: 30px;
  display: block;
  position: relative;
  border: 1px solid #006596;
  border-radius: 4px;
}
.desk-policy .policy-section:before {
  content: "";
  display: table;
}
.desk-policy .policy-section:after {
  content: "";
  display: table;
  clear: both;
}
.desk-policy .policy-section .policy-section-header {
  padding: 0 10px;
  line-height: 44px;
  height: 44px;
}
.desk-policy .policy-section .policy-section-header .policy-section-header-title {
  display: block;
  position: relative;
  height: 44px;
  line-height: 44px;
  font-size: 0.18rem;
  font-weight: 600;
}
.desk-policy .policy-section .policy-section-body {
  display: block;
  position: relative;
  padding: 0 10px 5px;
}
.desk-policy .policy-section .policy-section-body:before {
  content: "";
  display: table;
}
.desk-policy .policy-section .policy-section-body:after {
  content: "";
  display: table;
  clear: both;
}
.desk-policy .policy-section .policy-section-body .form-row {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 16px;
}
.desk-policy .policy-section .policy-section-body .form-row.new-row, .desk-policy .policy-section .policy-section-body .form-row:first-child {
  padding-left: 0;
}
.desk-policy .policy-section .policy-section-body .form-row .form-label {
  font-size: 0.12rem;
  line-height: 0.16rem;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
}
.desk-policy .policy-section .policy-section-body .form-row .form-label > span {
  border-bottom: 0;
  line-height: calc(
              0.16rem + 6px * 2
            );
  height: calc(0.16rem + 6px * 2);
  padding: 0 5px;
}
.desk-policy .policy-section .policy-section-body .form-row .form-input {
  line-height: 0.16rem;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
}
.desk-policy .policy-section .policy-section-body .form-row .form-input > span {
  line-height: calc(
              0.16rem + 6px * 2 - 2px
            );
  height: calc(0.16rem + 6px * 2);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 0 5px;
  background-color: #f2f2f2;
}
.desk-policy .policy-section .policy-section-body .form-row .form-input > div {
  line-height: calc(
              0.16rem + 6px * 2 - 2px
            );
  height: calc(0.16rem + 6px * 2);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 0 5px;
  background-color: #f2f2f2;
}
.desk-policy .policy-section .policy-section-body .form-row .form-input > div::before {
  border-bottom: none;
}
.desk-policy .policy-section .policy-section-body .form-row .form-input > div > input,
.desk-policy .policy-section .policy-section-body .form-row .form-input > div select {
  margin-top: -4px;
}
.desk-policy .policy-section .policy-section-body .form-table {
  display: table;
  border-top: 1px solid;
  border-right: 1px solid;
  padding-right: 0;
  margin-top: 10px;
}
.desk-policy .policy-section .policy-section-body .form-table .form-table-header-group {
  display: table-header-group;
}
.desk-policy .policy-section .policy-section-body .form-table .form-table-row-group {
  display: table-row-group;
}
.desk-policy .policy-section .policy-section-body .form-table .form-table-row {
  display: table-row;
}
.desk-policy .policy-section .policy-section-body .form-table .form-table-cell {
  display: table-cell;
  border-left: 1px solid;
  text-align: center;
  border-bottom: 1px solid;
  height: 50px;
  background-color: #f2f2f2;
  line-height: 50px;
}
.desk-policy .policy-section .policy-section-body .form-table .form-table-cell > div > input {
  text-align: center;
}
.desk-policy .policy-section .policy-section-body .array-panel-section {
  border: 1px solid #006596;
  border-radius: 4px;
  margin-top: 10px;
  padding-bottom: 5px;
}
.desk-policy .button {
  cursor: pointer;
  background-color: #006596;
  color: #ffffff;
  text-align: center;
  border-radius: 8px;
}
.desk-policy .button.addArrayPanel {
  height: 30px;
  line-height: 30px;
  width: 110px;
}
.desk-policy .button#addArrayRow {
  background-color: #006596;
}
.desk-policy .button.deletePanel {
  background-color: #a2a2a2;
  display: block;
  width: 44px;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
}
.desk-policy .button.deleteArrayPanel {
  background-color: #a2a2a2;
  display: block;
  width: 30px;
  height: 25px;
  line-height: 25px;
  margin-top: 5px;
}
.desk-policy .button#deleteArrayRow {
  background-color: #a2a2a2;
  color: #ffffff;
}
