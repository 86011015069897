@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

.product-list {
  display: block;
  position: relative;
  background-color: #ffffff;
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
}
.product-list .product-list-title {
  display: block;
  position: relative;
  background-color: #ffffff;
  height: 0.45rem;
}
.product-list .product-list-title span {
  display: block;
  position: relative;
  height: 0.45rem;
  line-height: 0.45rem;
  color: #666666;
  padding: 0 0.15rem;
  width: calc(100% - 0.15rem * 2);
  margin: 0 auto;
}
.product-list .product-list-title span:before {
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  border-top: 12px solid #bb1c22;
  left: 0;
  top: 38%;
}
.product-list .right-product-region {
  width: 100%;
  float: left;
  margin-left: 0;
  transition: margin-left 300ms ease-in;
}
.product-list.selectable .right-product-region {
  margin-left: 60px;
}

.hot-product-list-con {
  width: 100%;
  overflow-x: scroll;
  border-bottom: 0.005rem solid #e2e2e2;
  -webkit-overflow-scrolling: touch;
}
.hot-product-list-con .hot-product-list {
  width: 5rem;
}

.product {
  display: block;
  position: relative;
  background-color: #ffffff;
  padding: 0.1rem 0;
  border-bottom: 1px solid #e2e2e2;
  width: calc(100% - 0.15rem * 2);
  margin: 0 auto;
}
.product:last-child {
  border-bottom: 0;
}
.product:before {
  content: "";
  display: table;
}
.product:after {
  content: "";
  display: table;
  clear: both;
}
.product .img {
  background: #ffffff;
}
.product.hot {
  display: block;
  width: 2.5rem;
  padding: 0 10px;
  float: left;
}
.product.hot .content {
  flex: 1;
  height: 0.7rem;
  margin-top: 0.1rem;
}
.product.hot .content .title {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}
.product.hot .content .title:before {
  content: "";
  display: table;
}
.product.hot .content .title:after {
  content: "";
  display: table;
  clear: both;
}
.product.hot .content .title > span {
  font-size: 0.15rem;
  color: #515151;
  position: relative;
  padding-left: 5px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 100%;
  -webkit-box-orient: vertical;
}
.product.hot .content .slogan {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}
.product.hot .content .slogan span {
  display: block;
  padding-left: 5px;
  position: relative;
  line-height: 22px;
  color: #666666;
  font-size: 0.12rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.product.hot .content .like {
  display: block;
  position: relative;
  float: left;
  width: 50%;
  font-size: 0.12rem;
  line-height: 0.3rem;
  clear: both;
}
.product.hot .content .like span:last-child {
  float: left;
  line-height: 0.32rem;
}
.product.hot .content .like .icon {
  width: 0.15rem;
  height: 0.3rem;
  margin-right: 0.05rem;
  display: block;
  float: left;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.15rem;
}
.product.hot .content .like .icon.icon-like {
  background-image: url("../../../images/mobile/icon_like.png");
}
.product.hot .content .like .icon.icon-dislike {
  background-image: url("../../../images/mobile/icon_dislike.png");
}
.product.hot .content .like > span.liked + span,
.product.hot .content .like > span.liked {
  color: #bb1c22;
}
.product.hot .content .like > span > span {
  display: block;
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
  opacity: 0.9;
  line-height: 0.32rem;
  transition: visibility 0s linear, top 0.5s linear, opacity 0.5s linear, font-size 0.5s linear;
}
.product.hot .content .like.like-effect-shown > span > span {
  visibility: visible;
  font-size: 0.8rem;
  top: -0.31rem;
  opacity: 0;
}
.product.hot .content .price {
  display: block;
  position: relative;
  float: left;
  width: 50%;
  text-align: left;
  padding-left: 0.05rem;
  color: #bb1c22;
  font-size: 0.16rem;
  line-height: 0.3rem;
}
.product.hot .content .price span:last-child {
  font-size: 0.12rem;
  margin-left: 0.05rem;
  color: #333333;
}
.product.hot .img {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.product.hot .img img {
  display: block;
  height: 100%;
  width: 100%;
}
.product.plain {
  display: flex;
}
.product.plain .img {
  display: block;
  position: relative;
  float: left;
  height: 0.7rem;
  width: 1.1rem;
  margin-right: 0.1rem;
}
.product.plain .img img {
  height: 100%;
  width: 100%;
}
.product.plain .content {
  overflow: hidden;
  flex: 1;
  height: 0.7rem;
}
.product.plain .content .title {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.product.plain .content .title span {
  font-size: 0.15rem;
  color: #515151;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 100%;
  -webkit-box-orient: vertical;
}
.product.plain .content .slogan {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}
.product.plain .content .slogan > span {
  display: block;
  position: relative;
  line-height: 22px;
  color: #666666;
  font-size: 0.12rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.product.plain .content .like {
  display: block;
  position: relative;
  float: left;
  width: 50%;
  font-size: 0.12rem;
  line-height: 0.3rem;
  clear: both;
}
.product.plain .content .like span:last-child {
  float: left;
  line-height: 0.32rem;
}
.product.plain .content .like .icon {
  width: 0.15rem;
  height: 0.3rem;
  margin-right: 0.05rem;
  display: block;
  float: left;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.15rem;
}
.product.plain .content .like .icon.icon-like {
  background-image: url("../../../images/mobile/icon_like.png");
}
.product.plain .content .like .icon.icon-dislike {
  background-image: url("../../../images/mobile/icon_dislike.png");
}
.product.plain .content .like > span.liked + span,
.product.plain .content .like > span.liked {
  color: #bb1c22;
}
.product.plain .content .like > span > span {
  display: block;
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
  opacity: 0.9;
  line-height: 0.32rem;
  transition: visibility 0s linear, top 0.5s linear, opacity 0.5s linear, font-size 0.5s linear;
}
.product.plain .content .like.like-effect-shown > span > span {
  visibility: visible;
  font-size: 0.8rem;
  top: -0.31rem;
  opacity: 0;
}
.product.plain .content .price {
  display: block;
  position: relative;
  float: right;
  width: 50%;
  text-align: right;
  color: #bb1c22;
  font-size: 0.16rem;
  line-height: 0.3rem;
}
.product.plain .content .price span:last-child {
  font-size: 0.12rem;
  margin-left: 0.05rem;
  color: #333333;
}
.product.fav .left-edit-region {
  display: block;
  position: absolute;
  width: 76px;
  left: 0;
  top: 0;
  bottom: 0;
}
.product.fav .left-edit-region:before {
  content: "";
  display: block;
  position: absolute;
  height: 30px;
  width: 30px;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  border-radius: 100%;
  border: 1px solid #c8c8c8;
}
.product.fav .left-edit-region:after {
  content: "";
  display: block;
  position: absolute;
  height: 20px;
  width: 20px;
  left: calc(50% - 10px);
  top: calc(50% - 10px);
  border-radius: 100%;
  background-color: #c8c8c8;
  opacity: 0;
  transition: opacity 300ms ease-in;
}
.product.fav .left-edit-region.selected:after {
  opacity: 1;
}
.product.fav .img {
  display: block;
  position: relative;
  float: left;
  width: 100px;
}
.product.fav .img img {
  display: block;
  width: 100%;
  height: 94px;
}
.product.fav .title {
  display: block;
  position: relative;
  float: left;
  width: calc(100% - 100px);
  padding-left: 0.1rem;
}
.product.fav .title > span {
  font-size: 0.15rem;
  color: #515151;
}
.product.fav .slogan {
  display: block;
  position: relative;
  float: left;
  width: calc(100% - 100px);
  white-space: nowrap;
}
.product.fav .slogan span {
  display: block;
  position: relative;
  line-height: 22px;
  margin-left: 0.1rem;
  color: #666666;
}
@media (max-width: 320px) {
  .product.fav .slogan span {
    font-size: 0.12rem;
  }
}
.product.fav .price {
  display: block;
  position: relative;
  float: right;
  width: 50%;
  text-align: right;
  color: #b03432;
  font-size: 0.18rem;
  line-height: 0.32rem;
  height: 0.32rem;
}
@media (max-width: 320px) {
  .product.fav .price {
    font-size: 0.16rem;
  }
}
.product.fav .price span:last-child {
  font-size: 0.75em;
  color: #b6b6b6;
  margin-left: 5px;
}
.product.product-add .img {
  display: block;
  position: relative;
  float: left;
  width: 100px;
}
.product.product-add .img img {
  display: block;
  width: 100%;
  height: 94px;
}
.product.product-add .title {
  display: block;
  position: relative;
  float: left;
  width: calc(100% - 100px);
  padding-left: 0.1rem;
  display: flex;
}
.product.product-add .title > span {
  font-size: 0.15rem;
  color: #515151;
  flex: 6;
}
.product.product-add .title .checked {
  width: 50px;
  height: 30px;
  flex: 1;
  position: relative;
}
.product.product-add .title .checked > .fa {
  display: none;
  height: 100%;
  width: 100%;
  font-size: 0.28rem;
  text-align: center;
  line-height: 30px;
  color: #c8c8c8;
}
.product.product-add .title .checked > .selected {
  display: block;
  color: #fcb44d;
}
.product.product-add .title .checked > .notSelected {
  display: block;
}
.product.product-add .slogan {
  display: block;
  position: relative;
  float: left;
  width: calc(100% - 100px);
  white-space: nowrap;
}
.product.product-add .slogan span {
  display: block;
  position: relative;
  line-height: 22px;
  margin-left: 0.1rem;
  color: #666666;
}
@media (max-width: 320px) {
  .product.product-add .slogan span {
    font-size: 0.12rem;
  }
}
.product.product-add .like {
  display: block;
  position: relative;
  float: left;
  width: 50%;
  font-size: 0.18rem;
  line-height: 0.32rem;
  height: 0.32rem;
  clear: both;
}
@media (max-width: 320px) {
  .product.product-add .like {
    font-size: 0.16rem;
  }
}
.product.product-add .like > span.liked + span,
.product.product-add .like > span.liked {
  color: #bb1c22;
}
.product.product-add .like > span > span {
  display: block;
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
  opacity: 0.9;
  line-height: 0.32rem;
  transition: visibility 0s linear, top 0.5s linear, opacity 0.5s linear, font-size 0.5s linear;
}
.product.product-add .like.like-effect-shown > span > span {
  visibility: visible;
  font-size: 0.8rem;
  top: -0.31rem;
  opacity: 0;
}
.product.product-add .price {
  display: block;
  position: relative;
  float: right;
  width: 50%;
  text-align: right;
  color: #b03432;
  font-size: 0.18rem;
  line-height: 0.32rem;
  height: 0.32rem;
}
@media (max-width: 320px) {
  .product.product-add .price {
    font-size: 0.16rem;
  }
}
.product.product-add .price span:last-child {
  font-size: 0.75em;
  color: #b6b6b6;
  margin-left: 5px;
}
