@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

.life-bottom-menu {
  display: block;
  position: relative;
  background-color: #bb1c22;
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.258824);
}
.life-bottom-menu:before {
  content: "";
  display: table;
}
.life-bottom-menu:after {
  content: "";
  display: table;
  clear: both;
}
.life-bottom-menu .item {
  display: block;
  position: relative;
  float: left;
  text-align: center;
  min-height: 1px;
}
.life-bottom-menu .item .icon {
  display: block;
  position: relative;
  height: 29px;
  width: 100%;
  margin-top: 3px;
  margin-bottom: 3px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  color: #737373;
}
.life-bottom-menu .item .icon.icon-home {
  background-image: url("../../../../images/mobile/icon_home.png");
}
.life-bottom-menu .item .icon.icon-product {
  background-image: url("../../../../images/mobile/icon_product.png");
}
.life-bottom-menu .item .icon.icon-order {
  background-image: url("../../../../images/mobile/icon_order.png");
}
.life-bottom-menu .item .icon.icon-user {
  background-image: url("../../../../images/mobile/icon_user.png");
}
.life-bottom-menu .item .label {
  display: block;
  position: relative;
  line-height: 0.12rem;
  height: 0.12rem;
  width: 100%;
  margin-bottom: 1px;
  font-size: 0.12rem;
  color: #fff;
  transform: scale3d(0.8, 0.8, 0.8);
}
.life-bottom-menu .item.home .icon {
  font-size: 2em;
  line-height: 29px;
}
.life-bottom-menu .item.home.active .icon {
  color: #bb1c22;
}
.life-bottom-menu .item.category .icon {
  font-size: 1.8em;
  line-height: 29px;
  margin-top: 4px;
  margin-bottom: 2px;
}
.life-bottom-menu .item.category.active .icon {
  color: #bb1c22;
}
.life-bottom-menu .item.explore .icon {
  font-size: 1.8em;
  line-height: 29px;
}
.life-bottom-menu .item.explore.active .icon {
  color: #bb1c22;
}
.life-bottom-menu .item.me .icon {
  font-size: 1.8em;
  line-height: 29px;
}
.life-bottom-menu .item.me.active .icon {
  color: #bb1c22;
}
.life-bottom-menu .item.salient span {
  font-size: 2.4em;
  color: #ffffff;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  line-height: 64px;
  position: absolute;
  top: -12px;
  left: calc(50% - 35px);
  display: block;
  background-color: #bb1c22;
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.5);
}
.life-bottom-menu .item.active {
  background-color: #c49e62;
}
.life-bottom-menu .item:first-child:last-child {
  width: 100%;
}
.life-bottom-menu .item:first-child:nth-last-child(2),
.life-bottom-menu .item:first-child:nth-last-child(2) ~ .item {
  width: 50%;
}
.life-bottom-menu .item:first-child:nth-last-child(3),
.life-bottom-menu .item:first-child:nth-last-child(3) ~ .item {
  width: 33.3333333333%;
}
.life-bottom-menu .item:first-child:nth-last-child(4),
.life-bottom-menu .item:first-child:nth-last-child(4) ~ .item {
  width: 25%;
}
.life-bottom-menu .item:first-child:nth-last-child(5),
.life-bottom-menu .item:first-child:nth-last-child(5) ~ .item {
  width: 20%;
}
