@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

input::placeholder {
  color: #999999;
}

textarea::placeholder {
  color: #999999;
}

div.validate {
  display: block;
  position: relative;
}
div.validate.fail > select,
div.validate.fail > input {
  color: #c22000;
}
div.validate input,
div.validate select {
  display: block;
  width: 100%;
}
div.validate select {
  border: 0.01rem solid #cdcdcd;
}
div.validate input[type=checkbox],
div.validate input[type=radio] {
  width: auto;
}
div.validate > span.validation-failure {
  display: block;
  position: absolute;
  right: 0;
  top: 100%;
  height: 0.16rem;
  line-height: 0.16rem;
  font-size: 0.1rem;
  color: #c22000;
  transform: scale(0.9);
  left: -5%;
  opacity: 0;
  transition: opacity 500ms linear;
}
div.validate > span.validation-failure.shown {
  opacity: 1;
}
div.validate.password > input {
  display: inline-block;
  width: calc(100% - 20px);
}
div.validate.password > span.pwd-btn {
  display: inline-block;
  color: #0087c9;
  width: 20px;
  cursor: pointer;
}

.menu-group {
  display: block;
  position: relative;
  width: 100%;
}
.menu-group .menu-item {
  display: block;
  position: relative;
  width: 100%;
  height: 36px;
  background-color: #ffffff;
  overflow: hidden;
}
.menu-group .menu-item span.go-deep {
  font-size: 2.2em;
  transform: scaleX(0.8);
  margin-top: -2px;
}
.menu-group .check-menu-item {
  display: block;
  position: relative;
  width: 100%;
  height: 36px;
  background-color: #ffffff;
  padding-left: 16px;
  overflow: hidden;
}
.menu-group .check-menu-item:not(.checked) span.fa {
  display: none;
}
.menu-group .check-menu-item,
.menu-group .menu-item {
  cursor: pointer;
}
.menu-group .check-menu-item span.check,
.menu-group .check-menu-item span.go-deep,
.menu-group .check-menu-item span.icon,
.menu-group .check-menu-item span.text,
.menu-group .menu-item span.check,
.menu-group .menu-item span.go-deep,
.menu-group .menu-item span.icon,
.menu-group .menu-item span.text {
  display: block;
  position: relative;
  float: left;
  height: 36px;
  line-height: 36px;
}
.menu-group .check-menu-item span.text,
.menu-group .menu-item span.text {
  width: calc(100% - 40px);
  font-size: 0.14rem;
  color: #666666;
}
.menu-group .check-menu-item span.check,
.menu-group .check-menu-item span.go-deep,
.menu-group .check-menu-item span.icon,
.menu-group .menu-item span.check,
.menu-group .menu-item span.go-deep,
.menu-group .menu-item span.icon {
  width: 40px;
  color: #999999;
  min-height: 1px;
}
.menu-group .check-menu-item span.icon,
.menu-group .menu-item span.icon {
  text-align: left;
  width: 24px;
}
.menu-group .check-menu-item span.icon + span.text,
.menu-group .menu-item span.icon + span.text {
  width: calc(100% - 40px - 24px);
}
.menu-group .check-menu-item hr,
.menu-group .menu-item hr {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  border: 0;
  border-bottom: 1px solid #e6e6e6;
  margin: 0;
  padding: 0;
}
.menu-group .check-menu-item:last-child hr,
.menu-group .menu-item:last-child hr {
  display: none;
}

.search {
  display: block;
  position: relative;
}
.search:before {
  content: "";
  display: table;
}
.search:after {
  content: "";
  display: table;
  clear: both;
}
.search input {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  padding-right: calc(0.2rem + 20px);
}
.search span {
  display: block;
  position: absolute;
  width: calc(0.2rem + 20px);
  height: 44px;
  line-height: 44px;
  padding-left: 10px;
  padding-right: 10px;
  color: #9f9f9f;
  top: 0;
  right: 0;
}

.tab {
  display: block;
  position: relative;
  background-color: #ffffff;
}
.tab:before {
  content: "";
  display: table;
}
.tab:after {
  content: "";
  display: table;
  clear: both;
}
.tab .tab-item {
  display: block;
  position: relative;
  float: left;
  text-align: center;
}
.tab .tab-item > .tab-item-label {
  display: block;
  position: relative;
  height: 44px;
  line-height: 44px;
  font-size: 0.16rem;
  padding-left: 5px;
  padding-right: 5px;
}
.tab hr {
  display: block;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 2px;
  border: 0;
  border-top: 2px solid #bb1c22;
  margin: 0;
  padding: 0;
  transition: left 0.5s ease-in-out;
}
.tab .tab-item:first-child:nth-last-child(2),
.tab .tab-item:first-child:nth-last-child(2) ~ .tab-item,
.tab .tab-item:first-child:nth-last-child(2) ~ hr {
  width: 100%;
}
.tab .tab-item:first-child:nth-last-child(3),
.tab .tab-item:first-child:nth-last-child(3) ~ .tab-item,
.tab .tab-item:first-child:nth-last-child(3) ~ hr {
  width: 50%;
}
.tab .tab-item:first-child:nth-last-child(4),
.tab .tab-item:first-child:nth-last-child(4) ~ .tab-item,
.tab .tab-item:first-child:nth-last-child(4) ~ hr {
  width: 33.3333333333%;
}
.tab .tab-item:first-child:nth-last-child(5),
.tab .tab-item:first-child:nth-last-child(5) ~ .tab-item,
.tab .tab-item:first-child:nth-last-child(5) ~ hr {
  width: 25%;
}
.tab .tab-item:first-child:nth-last-child(6),
.tab .tab-item:first-child:nth-last-child(6) ~ .tab-item,
.tab .tab-item:first-child:nth-last-child(6) ~ hr {
  width: 20%;
}
.tab.active-0 hr {
  left: 0;
}
.tab.active-1 .tab-item:first-child:nth-last-child(3) ~ hr {
  left: 50%;
}
.tab.active-1 .tab-item:first-child:nth-last-child(4) ~ hr {
  left: 33.3333333333%;
}
.tab.active-1 .tab-item:first-child:nth-last-child(5) ~ hr {
  left: 25%;
}
.tab.active-1 .tab-item:first-child:nth-last-child(6) ~ hr {
  left: 20%;
}
.tab.active-2 .tab-item:first-child:nth-last-child(4) ~ hr {
  left: 66.6666666667%;
}
.tab.active-2 .tab-item:first-child:nth-last-child(5) ~ hr {
  left: 50%;
}
.tab.active-2 .tab-item:first-child:nth-last-child(6) ~ hr {
  left: 40%;
}
.tab.active-3 .tab-item:first-child:nth-last-child(5) ~ hr {
  left: 75%;
}
.tab.active-3 .tab-item:first-child:nth-last-child(6) ~ hr {
  left: 60%;
}
.tab.active-4 .tab-item:first-child:nth-last-child(6) ~ hr {
  left: 80%;
}

.load-more,
.no-more {
  display: block;
  position: relative;
  line-height: 36px;
  height: 36px;
  background-color: transparent;
  color: #666666;
  text-align: center;
}
.load-more > span,
.no-more > span {
  display: block;
  position: relative;
}

.list {
  display: block;
  position: relative;
}
.list:before {
  content: "";
  display: table;
}
.list:after {
  content: "";
  display: table;
  clear: both;
}

.row-text {
  display: block;
  position: relative;
  padding-bottom: 16px;
}
.row-text:before {
  content: "";
  display: table;
}
.row-text:after {
  content: "";
  display: table;
  clear: both;
}
.row-text .label {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: 18px;
  line-height: 18px;
  font-size: 0.7em;
}
.row-text .validate {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}
.row-text .validate input {
  height: 26px;
  padding-top: calc((26px - 0.2rem) / 2);
  padding-bottom: calc((26px - 0.2rem) / 2);
}
.row-text .validate.fail:before {
  content: "";
  display: block;
  position: absolute;
  border-bottom: 1px solid #c22000;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.row-text .validate + hr {
  display: block;
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  height: 1px;
  border: 0;
  border-top: 1px solid #e6e6e6;
  margin: 0;
  padding: 0;
}

.row-comments {
  display: block;
  position: relative;
}
.row-comments > span {
  display: block;
  position: relative;
}

.bottom-button {
  display: block;
  float: left;
  height: 0.48rem;
  line-height: 0.48rem;
  text-align: center;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.258824);
  position: relative;
}
.bottom-button:last-child {
  color: #bb1c22;
}
.bottom-button:after {
  content: "";
  height: 33%;
  top: 33%;
  right: 0;
  display: block;
  position: absolute;
  border-right: 0.01rem solid #cdcdcd;
}
.bottom-button:last-child::after {
  display: none;
}
.bottom-button.twins {
  width: 50%;
  position: relative;
}
.bottom-button.twins:nth-child(2) {
  color: #bb1c22;
}
.bottom-button.tribble {
  width: 33.33%;
}
.bottom-button.second {
  background-color: #eeeeee;
  color: #e6575d;
}

.upload-img {
  height: 100px;
  width: 100px;
  position: relative;
  background: #ffffff;
  border: 1px solid #006596;
  margin: 0 auto;
}
.upload-img .img {
  height: 100%;
  width: 100%;
}
.upload-img .upload-button {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
}

.search-bar {
  width: 100%;
  padding: 0.1rem;
  background-color: #eeeeee;
}
.search-bar .search-content {
  position: relative;
  height: 0.3rem;
}
.search-bar .search-content .keyword {
  width: 100%;
  height: 0.3rem;
  line-height: 0.3rem;
  border-radius: 0.15rem;
  background-color: #ffffff;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  border: 1px solid #e2e2e2;
  -webkit-appearance: none;
}
.search-bar .search-content .serach-icon {
  position: absolute;
  width: 0.5rem;
  height: 100%;
  top: 0;
  right: 0;
}
.search-bar .search-content .serach-icon .my-icon {
  float: right;
  margin-right: 0.1rem;
  width: 0.2rem;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.2rem;
}
.search-bar .search-content .serach-icon .my-icon.icon-search-red {
  background-image: url("../../images/mobile/icon_search_red.png");
}

html.csms-lang-zh .row-text,
html.csms-lang-zh-cn .row-text,
html.csms-lang-zh-tw .row-text {
  padding-bottom: 0;
}
html.csms-lang-zh .row-text .label,
html.csms-lang-zh-cn .row-text .label,
html.csms-lang-zh-tw .row-text .label {
  width: 30%;
  height: 44px;
  line-height: 44px;
  font-size: 0.14rem;
}
html.csms-lang-zh .row-text input,
html.csms-lang-zh-cn .row-text input,
html.csms-lang-zh-tw .row-text input {
  height: 44px;
  padding-top: calc((44px - 0.2rem) / 2);
  padding-bottom: calc((44px - 0.2rem) / 2);
}
html.csms-lang-zh .row-text .validate,
html.csms-lang-zh-cn .row-text .validate,
html.csms-lang-zh-tw .row-text .validate {
  float: left;
  width: 70%;
}
html.csms-lang-zh .row-text .validate.fail:before,
html.csms-lang-zh-cn .row-text .validate.fail:before,
html.csms-lang-zh-tw .row-text .validate.fail:before {
  bottom: 10px;
}
html.csms-lang-zh .row-text .validate.fail span.validation-failure.shown,
html.csms-lang-zh-cn .row-text .validate.fail span.validation-failure.shown,
html.csms-lang-zh-tw .row-text .validate.fail span.validation-failure.shown {
  top: calc(100% - 10px);
}
html.csms-lang-zh .row-text .validate + hr,
html.csms-lang-zh-cn .row-text .validate + hr,
html.csms-lang-zh-tw .row-text .validate + hr {
  bottom: 10px;
}
html.csms-lang-zh .bottom-button.gap,
html.csms-lang-zh-cn .bottom-button.gap,
html.csms-lang-zh-tw .bottom-button.gap {
  letter-spacing: 16px;
  padding-left: 32px;
}

@media (max-width: 320px) {
  .row-text .label {
    width: 40%;
  }
  .row-text .validate {
    width: 60%;
  }
}
