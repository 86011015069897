.search-wrap input {
  background: rgb(255, 255, 255) !important;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 0.45rem;
  border-radius: 8px !important;
  padding-left: 0.15rem;
  font-size: 0.15rem;
}
.search-wrap .searchClick {
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  width: 2.8rem;
  height: 0.45rem;
  border-radius: 8px;
  font-size: 0.15rem;
  margin: 0.15rem;
  padding: 0;
  padding-left: 0.15rem;
  line-height: 0.45rem;
}
.search-wrap .search-box-window {
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
  background: rgb(248, 248, 248);
  top: 0;
  left: 0;
}
.search-wrap .search-box-window .inputarea {
  height: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.search-wrap .search-box-window .search-input {
  height: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.search-wrap .search-box-window .search-input > div {
  margin: 0.15rem;
  position: relative;
  margin-right: 0.15rem;
  width: calc(100% - 0.3rem);
}
.search-wrap .search-box-window .search-input span {
  color: rgb(228, 57, 66);
  font-size: 0.18rem;
  height: 0.45rem;
  line-height: 0.48rem;
  margin-right: 0.15rem;
  display: block;
}
.search-wrap .search-box-window .search-input .clear-icon {
  width: 0.19rem;
  height: 0.19rem;
  display: block;
  background: url(../../../images/wechat/clear.png) no-repeat;
  background-size: cover;
  position: absolute;
  right: 0.15rem;
  margin-right: 0;
  top: calc(50% - 0.08rem);
}
.search-wrap .search-box-window .search-icon {
  width: 0.45rem;
}
.search-wrap .search-box-window .menuList {
  width: 100%;
  margin: 0;
  padding: 0 0.15rem;
  height: calc(100% - 0.75rem);
  overflow: scroll;
}
.search-wrap .search-box-window .menuList .menuItem {
  background-image: linear-gradient(to right, #ccc 0%, #ccc 50%, transparent 50%);
  background-size: 0.15rem 0.01rem;
  background-position: 0 bottom;
  background-repeat: repeat-x;
  height: 0.49rem;
  line-height: 0.49rem;
  font-size: 0.15rem;
  color: #333;
  font-weight: 500;
}
.search-wrap .search-box-window .menuList .menuItem .hightlight {
  color: #666;
  font-weight: 400;
}
.search-wrap .show {
  display: block;
}
.search-wrap .hide {
  display: none;
}
.search-wrap .blur {
  color: #999;
}
.search-wrap .focus {
  color: #333;
}
