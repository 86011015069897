@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

#loading-container-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1500;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.page-loading {
  position: relative;
  top: calc(50% - 60px);
  color: #ffffff;
  text-align: center;
}
.page-loading .loading {
  background: url("../../../images/newloading.gif") no-repeat;
  background-size: cover;
  margin: 0 auto;
  width: 2.1rem;
  background-position: center center;
  height: 0.3rem;
}
