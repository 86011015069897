@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

.logo {
  display: block;
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.logo .logo-front {
  display: block;
  position: absolute;
  left: calc(50% - 40px);
  width: 100px;
  height: 70px;
  background-image: url(../../../images/cloud-front-blue.png);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 3;
}
.logo .logo-middle {
  display: block;
  position: absolute;
  left: calc(50% - 50px);
  width: 100px;
  height: 70px;
  background-image: url(../../../images/logo_bilife.png);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2;
}
.logo .logo-back {
  display: block;
  position: absolute;
  left: calc(50% - 60px);
  width: 100px;
  height: 70px;
  background-image: url(../../../images/cloud-back-blue.png);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}
.logo .logo-text {
  display: block;
  position: relative;
  text-align: center;
  color: #1b1b1b;
  font-size: 0.24rem;
  font-weight: 200;
  padding-top: 80px;
  line-height: 0.24rem;
  padding-bottom: 10px;
}
