@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

.env-mobile:not(.b2c) .page body {
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.env-mobile:not(.b2c) .page .page-header,
.env-mobile:not(.b2c) .page .page-footer {
  position: fixed;
}
.env-mobile:not(.b2c) .page .page-body {
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.env-mobile:not(.b2c) .page .page-body .wx-my-receipt .no-item,
.env-mobile:not(.b2c) .page .page-body .online-visit .no-item {
  min-height: 100vh;
}
.env-mobile:not(.b2c) .page .page-body #signature-html-container #anysign-container {
  position: fixed;
}
.env-mobile:not(.b2c) .page .page-body #signature-html-container #anysign-container #dialog {
  height: 100% !important;
  padding-top: 44px;
}

.env-mobile.b2c .page body {
  min-height: 100vh;
  overflow-y: auto;
  height: auto !important;
}
.env-mobile.b2c .page .page-header,
.env-mobile.b2c .page .page-footer {
  position: fixed;
}
.env-mobile.b2c .page .page-body {
  min-height: 100vh;
  overflow-y: auto;
  height: auto !important;
}
.env-mobile.b2c .page .page-body .wx-my-receipt .no-item,
.env-mobile.b2c .page .page-body .online-visit .no-item {
  min-height: 100vh;
}
.env-mobile.b2c .page .page-body #signature-html-container #anysign-container {
  position: fixed;
}
.env-mobile.b2c .page .page-body #signature-html-container #anysign-container #dialog {
  height: 100% !important;
  padding-top: 44px;
}

.env-android.env-mobile.b2c .page.sigScroll .page-body {
  height: 100vh !important;
}

.env-android.env-mobile.b2c .page.sigScroll {
  overflow: auto;
}
