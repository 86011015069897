@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

.desk-top-menu {
  display: block;
  position: fixed;
  height: 64px;
  background-color: #006596;
  width: 100%;
}
.desk-top-menu:before {
  content: "";
  display: table;
}
.desk-top-menu:after {
  content: "";
  display: table;
  clear: both;
}
.desk-top-menu .logo {
  float: left;
  width: 200px;
}
.desk-top-menu .logo:before {
  content: "";
  display: table;
}
.desk-top-menu .logo:after {
  content: "";
  display: table;
  clear: both;
}
.desk-top-menu .logo .logo-back,
.desk-top-menu .logo .logo-front {
  display: none;
}
.desk-top-menu .logo .logo-middle {
  float: left;
  position: relative;
  left: auto;
  height: 32px;
  width: 100%;
  background-size: contain;
  margin-top: 16px;
}
.desk-top-menu .logo .logo-text {
  float: left;
  padding-top: 0;
  padding-bottom: 0;
  color: #ffffff;
  line-height: 64px;
}
.desk-top-menu .desk-menus {
  display: block;
  position: relative;
  float: left;
  height: 64px;
  line-height: 64px;
}
.desk-top-menu .desk-menus:before {
  content: "";
  display: table;
}
.desk-top-menu .desk-menus:after {
  content: "";
  display: table;
  clear: both;
}
.desk-top-menu .desk-menus .desk-menu {
  display: block;
  position: relative;
  float: left;
  padding-left: 16px;
  padding-right: 16px;
  color: #ffffff;
  font-size: 0.16rem;
  font-weight: 400;
  height: 24px;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.desk-top-menu .desk-menus .desk-menu.shown .desk-sub-menus {
  display: block;
}
.desk-top-menu .desk-menus .desk-menu .desk-sub-menus,
.desk-top-menu .desk-menus .desk-menu .secondary-sub-menus {
  display: none;
  position: absolute;
  background-color: #006596;
  min-width: 200px;
}
.desk-top-menu .desk-menus .desk-menu .desk-sub-menu,
.desk-top-menu .desk-menus .desk-menu .secondary-sub-menu {
  display: block;
  position: relative;
  padding: 5px 8px 5px 16px;
}
.desk-top-menu .desk-menus .desk-menu .desk-sub-menu:hover,
.desk-top-menu .desk-menus .desk-menu .secondary-sub-menu:hover {
  background-color: #0087c9;
}
.desk-top-menu .desk-menus .desk-menu .desk-sub-menus {
  left: 0;
  z-index: 1;
  padding-top: 10px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.258824);
}
.desk-top-menu .desk-menus .desk-menu .desk-sub-menus .desk-sub-menu .direction {
  float: right;
  line-height: 24px;
}
.desk-top-menu .desk-menus .desk-menu .desk-sub-menus .desk-sub-menu .secondary-sub-menus {
  top: 0;
  left: calc(100% - 8px);
  box-shadow: 3px 0 4px rgba(0, 0, 0, 0.258824), -3px 0 4px rgba(0, 0, 0, 0.258824), 0 3px 4px rgba(0, 0, 0, 0.258824);
}
.desk-top-menu .desk-menus .desk-menu .desk-sub-menus .desk-sub-menu.shown .secondary-sub-menus {
  display: block;
}
.desk-top-menu .right-menus {
  display: block;
  position: relative;
  float: right;
  height: 64px;
  line-height: 64px;
}
.desk-top-menu .right-menus:before {
  content: "";
  display: table;
}
.desk-top-menu .right-menus:after {
  content: "";
  display: table;
  clear: both;
}
.desk-top-menu .right-menus > div {
  display: block;
  position: relative;
  float: left;
  color: #ffffff;
  font-size: 0.16rem;
  font-weight: 400;
  padding-left: 16px;
  padding-right: 16px;
  height: 24px;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.desk-top-menu .right-menus > div.who-am-i {
  cursor: default;
}
