@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

div.sth {
  background: #f2f2f2;
  padding-bottom: 10px;
  /**add by sanxiang.zeng  2018 Jan 29*/
}
div.sth input[type=checkbox],
div.sth input[type=date],
div.sth input[type=email],
div.sth input[type=number],
div.sth input[type=password],
div.sth input[type=radio],
div.sth input[type=search],
div.sth input[type=tel],
div.sth input[type=text],
div.sth input[type=url],
div.sth select,
div.sth textarea {
  color: #666666;
}
div.sth.with-bottom-button {
  padding-bottom: 58px;
}
div.sth .sth-icon-labels {
  display: block;
  position: relative;
}
div.sth .sth-icon-labels:before {
  content: "";
  display: table;
}
div.sth .sth-icon-labels:after {
  content: "";
  display: table;
  clear: both;
}
div.sth .sth-icon-labels .sth-icon-labels-icon {
  display: block;
  position: relative;
  float: left;
  padding-right: 5px;
  line-height: 0.2rem;
}
div.sth .sth-icon-labels .sth-icon-labels-icon img {
  height: 18px;
  width: 18px;
}
div.sth .sth-icon-labels .sth-icon-labels-label {
  display: block;
  position: relative;
  float: left;
}
div.sth .sth-job-category {
  width: 100%;
}
div.sth .sth-step-in-row {
  display: block;
  position: relative;
  height: 0.16rem;
  line-height: 0.16rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.sth .sth-step-in-row span {
  display: block;
  position: relative;
}
div.sth .sth-step-in-row:after {
  display: block;
  position: absolute;
  top: 0;
  right: 2px;
  content: "\f105";
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 0.16rem;
  line-height: 0.16rem;
  font-size: 0.2rem;
  color: #b3b3b3;
  transform: scale(1.3);
}
div.sth .sth-step-in-row .sth-step-in-content {
  position: fixed;
  left: 100vw;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  overflow-y: auto;
  height: 100%;
  background: #f2f2f2;
  z-index: 50;
}
div.sth .sth-step-in-row .sth-step-in-content .sth-container-body {
  padding-top: 54px;
  padding-bottom: 10px;
}
div.sth .sth-step-in-row .sth-step-in-content.narrow > .sth-container-body {
  padding-left: 16px;
  padding-right: 16px;
}
div.sth .sth-step-in-row .sth-step-in-content.narrow > .sth-container-body .sth-button.bottom-button {
  margin-left: -16px;
  margin-right: -16px;
}
div.sth .sth-step-in-row .sth-step-in-content.with-bottom-button > .sth-container-body {
  padding-bottom: 58px;
}
div.sth .sth-step-in-row .sth-step-in-content > .top-bar {
  position: fixed;
  z-index: 1;
  width: 100vw;
}
div.sth .sth-step-in-row .sth-step-in-content > .top-bar .title {
  font-size: 0.14rem;
}
div.sth .sth-step-in-row.sth-single-step-in-row {
  background: #ffffff;
  padding: 0 16px;
  height: 44px;
  line-height: 44px;
  margin-bottom: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}
div.sth .sth-step-in-row.sth-single-step-in-row:after {
  height: 44px;
  line-height: 44px;
  right: 18px;
}
div.sth .sth-step-in-rows > .sth-step-in-row:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
}
div.sth .sth-article {
  background: #fff;
}
div.sth .sth-article span {
  line-height: 0.2rem;
  white-space: normal;
  display: block;
  padding: 0.05rem 0;
}
div.sth .sth-article span > b {
  font-weight: 0;
  font-size: 0.16rem;
  color: #f00;
}
div.sth .sth-article span.title {
  font-weight: bold;
  border-bottom: 0.5px dashed #e2e2e2;
}
div.sth .sth-article span.header {
  font-size: 0.16rem;
  font-weight: bold;
}
div.sth .sth-article span.bold {
  font-weight: bold;
}
div.sth .sth-button.bottom-button {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
}
div.sth .sth-radio {
  display: block;
  position: relative;
  height: 0.45rem;
  line-height: 0.45rem;
}
div.sth .sth-radio:before {
  content: "";
  display: table;
}
div.sth .sth-radio:after {
  content: "";
  display: table;
  clear: both;
}
div.sth .sth-radio > span {
  display: block;
  position: relative;
  float: left;
  cursor: pointer;
  width: 48%;
}
div.sth .sth-radio > span:before {
  content: "";
  display: table;
}
div.sth .sth-radio > span:after {
  content: "";
  display: table;
  clear: both;
}
div.sth .sth-radio > span:not(:last-child) {
  margin-right: 5px;
}
div.sth .sth-radio > span .sth-radio-icon {
  display: block;
  position: relative;
  float: left;
  width: 25px;
  height: 10px;
}
div.sth .sth-radio > span .sth-radio-icon:before {
  content: "";
  display: block;
  position: absolute;
  top: 0.125rem;
  left: 0;
  width: 0.2rem;
  height: 0.2rem;
  border: 1px solid #bb1c22;
  border-radius: 100%;
}
div.sth .sth-radio > span .sth-radio-icon:after {
  content: "";
  display: block;
  position: absolute;
  top: 0.225rem;
  left: 0.1rem;
  width: 0;
  height: 0;
  background: #bb1c22;
  border-radius: 100%;
  transition: top 200ms linear, left 200ms linear, width 200ms linear, height 200ms linear;
}
div.sth .sth-radio > span .sth-label {
  display: block;
  position: relative;
  float: left;
}
div.sth .sth-radio > span.checked .sth-radio-icon:after {
  top: 0.175rem;
  left: 0.05rem;
  width: 0.1rem;
  height: 0.1rem;
}
div.sth .sth-radio.sth-radio-vertical {
  height: auto;
}
div.sth .sth-radio.sth-radio-vertical > span {
  width: 100%;
}
div.sth .sth-radio.sth-radio-button {
  height: auto;
  display: flex;
  flex-wrap: wrap;
}
div.sth .sth-radio.sth-radio-button > span {
  flex: 1 0 18%;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  margin-right: 5%;
}
div.sth .sth-radio.sth-radio-button > span > span {
  width: 100%;
  text-align: center;
}
div.sth .sth-radio.sth-radio-button > span.checked {
  background: #bb1c22;
  color: #fff;
}
div.sth .sth-date {
  display: inline-block;
  position: relative;
  border: 0.01rem solid #cdcdcd;
}
div.sth .sth-date .sth-date-display,
div.sth .sth-date .sth-date-invisible {
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 2px;
  height: 0.45rem;
  padding: 0.01rem 0.025rem;
}
div.sth .sth-date .sth-date-picker {
  display: block;
  line-height: 0.45rem;
  color: #999999;
  padding: 0 0.025rem;
}
div.sth .sth-date .sth-date-display-native {
  pointer-events: none;
  cursor: pointer;
}
div.sth .sth-date .sth-date-invisible {
  opacity: 0;
  color: transparent;
  background: transparent;
  border: none;
  box-shadow: none;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
div.sth .sth-birth-age {
  display: block;
  position: relative;
}
div.sth .sth-birth-age:before {
  content: "";
  display: table;
}
div.sth .sth-birth-age:after {
  content: "";
  display: table;
  clear: both;
}
div.sth .sth-birth-age .sth-age {
  float: left;
  width: calc(35% - 5px);
  margin-right: 5px;
}
div.sth .sth-birth-age .sth-birth {
  float: left;
  width: 65%;
}
div.sth .sth-dropdown.sth-dropdown-no-value {
  color: gray;
}
div.sth .sth-simple-job-mask {
  border-radius: 2px;
  line-height: 0.45rem;
  padding: 0.01rem 0.07rem;
  width: 100%;
  color: #666666;
  position: absolute;
  pointer-events: none;
}
div.sth .sth-simple-job-mask span {
  background-color: #fff;
}
div.sth .sth-dropdown,
div.sth .sth-text {
  border: 0.01rem solid #cdcdcd;
  border-radius: 2px;
  height: 0.45rem;
  padding: 0.01rem 0.025rem;
}
div.sth #sth-text {
  padding: initial;
}
div.sth .sth-label {
  height: 0.45rem;
  line-height: 0.45rem;
}
div.sth .sth-vertical-labels {
  display: block;
  position: relative;
}
div.sth .sth-vertical-labels .sth-label {
  display: block;
  border-bottom: 1px solid #eeeeee;
}
div.sth .sth-vertical-labels .sth-label:last-child {
  border-bottom: 0;
}
div.sth .sth-textarea {
  border: 0.01rem solid #cdcdcd;
  border-radius: 2px;
  height: 1.35rem;
  padding: 0.01rem 0.025rem;
  width: 100%;
}
div.sth .sth-toggle {
  display: block;
  position: relative;
  height: 0.3rem;
  width: 0.55rem;
  background: #cccccc;
  border-radius: 0.15rem;
  cursor: pointer;
}
div.sth .sth-toggle .sth-toggle-false {
  float: right;
  width: 50%;
  text-align: center;
  color: #999999;
  line-height: 0.3rem;
}
div.sth .sth-toggle .sth-toggle-true {
  float: left;
  color: #ffffff;
  width: 50%;
  text-align: center;
  line-height: 0.3rem;
}
div.sth .sth-toggle:after {
  content: "";
  display: block;
  position: absolute;
  width: 0.29rem;
  height: 0.29rem;
  border-radius: 100%;
  left: 1px;
  top: 1px;
  background: #fafafa;
  transition: left 200ms linear;
}
div.sth .sth-toggle.sth-toggle-checked {
  background: #bb1c22;
}
div.sth .sth-toggle.sth-toggle-checked:after {
  left: 0.255rem;
}
div.sth .sth-toggle.sth-label-toggle {
  border-radius: 2px;
  background: #bb1c22;
}
div.sth .sth-toggle.sth-label-toggle > span {
  display: block;
  position: relative;
  float: left;
  width: 50%;
  text-align: center;
  color: #ffffff;
}
div.sth .sth-toggle.sth-label-toggle:after {
  border-radius: 2px;
  width: 50%;
}
div.sth .sth-toggle.sth-label-toggle.sth-toggle-checked:after {
  left: calc(50% - 1px);
}
div.sth .sth-spread {
  position: relative;
}
div.sth .sth-spread:before {
  content: "";
  display: table;
}
div.sth .sth-spread:after {
  content: "";
  display: table;
  clear: both;
}
div.sth .sth-spread .sth-spread-header .sth-spread-header-cell {
  position: relative;
  float: left;
  font-weight: 500;
}
div.sth .sth-spread .sth-spread-body-row .sth-spread-body-cell {
  position: relative;
  float: left;
  color: #999999;
  font-size: 0.12rem;
  min-height: 1px;
}
div.sth .sth-spread .sth-spread-body-row:last-child {
  border-bottom: 0;
}
div.sth .sth-spread .sth-spread-body-row,
div.sth .sth-spread .sth-spread-header {
  position: relative;
  border-bottom: 1px solid #d5d5d5;
}
div.sth .sth-spread .sth-spread-body-row:before,
div.sth .sth-spread .sth-spread-header:before {
  content: "";
  display: table;
}
div.sth .sth-spread .sth-spread-body-row:after,
div.sth .sth-spread .sth-spread-header:after {
  content: "";
  display: table;
  clear: both;
}
div.sth .sth-colunm {
  width: 100%;
  border: 0.005rem solid #000;
  border-spacing: 0;
}
div.sth .sth-colunm tr {
  border-bottom: 0.005rem solid #000;
}
div.sth .sth-colunm tr td {
  text-align: center;
  line-height: 0.3rem;
  border-right: 0.005rem solid #000;
  border-bottom: 0.005rem solid #000;
}
div.sth .sth-colunm tbody tr:last-child td {
  border-bottom: 0;
}
div.sth .sth-colunm tbody tr td:last-child {
  border-right: 0;
}
div.sth .sth-colunm thead tr td {
  font-weight: bold;
}
div.sth .sth-colunm thead tr td:last-child {
  border-right: 0;
}
div.sth .sth-container-body {
  display: block;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
div.sth .sth-body-none {
  height: 0;
  padding: 0;
}
div.sth .sth-body-none .sth-step-in-row {
  height: 0;
}
div.sth .sth-cell {
  display: block;
  position: relative;
}
div.sth .sth-cell:before {
  content: "";
  display: table;
}
div.sth .sth-cell:after {
  content: "";
  display: table;
  clear: both;
}
div.sth .sth-cell > * {
  display: block;
  position: relative;
  float: left;
}
div.sth .sth-cell > *:not(.sth-label) {
  width: 63%;
}
div.sth .sth-cell > *:not(.sth-label).sth-toggle {
  width: 0.55rem;
  height: 0.3rem;
  margin-top: 0.075rem;
}
div.sth .sth-cell > *:not(.sth-label).sth-toggle .sth-toggle-false {
  float: right;
  width: 50%;
  text-align: center;
  color: #999999;
  line-height: 0.3rem;
}
div.sth .sth-cell > *:not(.sth-label).sth-toggle .sth-toggle-true {
  float: left;
  color: #ffffff;
  width: 50%;
  text-align: center;
  line-height: 0.3rem;
}
div.sth .sth-cell > .unit {
  width: 53%;
}
div.sth .sth-cell > .sth-cell-unit {
  width: 10%;
  float: right;
  text-align: center;
  line-height: 0.45rem;
}
div.sth .sth-cell > .sth-label {
  width: 35%;
}
div.sth .sth-cell .sth-address {
  display: flex;
}
div.sth .sth-cell .sth-address .sth-dropdown:not(:last-child) {
  margin-right: 5px;
}
div.sth .sth-cell .sth-address .sth-dropdown {
  width: calc((100% - 10px) / 3);
}
div.sth .sth-panel {
  display: block;
  position: relative;
  background-color: #ffffff;
  padding: 0 0.15rem;
  margin-bottom: 0.1rem;
  box-sizing: border-box;
}
div.sth .sth-panel:before {
  content: "";
  display: table;
}
div.sth .sth-panel:after {
  content: "";
  display: table;
  clear: both;
}
div.sth .sth-panel .sth-panel-header {
  display: block;
  position: relative;
  font-size: 0.2rem;
  font-weight: 500;
  color: #666666;
  text-align: center;
  line-height: 0.45rem;
  height: 0.45rem;
}
div.sth .sth-panel .sth-panel-header .sth-panel-header-icon {
  color: #bb1c22;
  padding-right: 3px;
}
div.sth .sth-panel .sth-panel-header .right-label {
  float: right;
}
div.sth .sth-panel .sth-panel-header hr {
  position: absolute;
  display: block;
  margin: 0;
  border: 0;
  border-top: 2px solid #d9d9d9;
  width: 100%;
  bottom: 0;
  transition: opacity 400ms linear;
}
div.sth .sth-panel .sth-panel-header .collapsible-btn {
  display: none;
  transition: transform 400ms linear;
  color: #999999;
  line-height: 0.45rem;
  height: 0.45rem;
}
div.sth .sth-panel .sth-panel-header .clause-btn {
  display: none;
  margin-left: 30px;
  padding: 1px;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  background: blue;
  color: #fff;
}
div.sth .sth-panel .sth-panel-header .sth-toggle {
  float: right;
  margin-top: 0.075rem;
}
div.sth .sth-panel .sth-panel-header > .sth-label:last-child {
  line-height: 0.45rem;
  height: 0.45rem;
  float: right;
}
div.sth .sth-panel .sth-panel-header.left {
  text-align: left;
}
div.sth .sth-panel .sth-panel-header.shift-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: auto;
}
div.sth .sth-panel .sth-panel-header.shift-panel .title {
  flex: 1 0 30%;
  text-align: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #f2f2f2;
  border: 1px solid #e2e2e2;
  color: #666666;
  font-size: 0.14rem;
  line-height: 0.3rem;
  margin: 1%;
  white-space: nowrap;
}
div.sth .sth-panel .sth-panel-header.shift-panel .title.active {
  background: #ffeaea;
  color: #bb1c22;
  border: 0.005rem solid #bb1c22;
}
div.sth .sth-panel .sth-panel-header.declaration-panel {
  font-size: 0.14rem;
  line-height: 0.22rem;
  height: auto;
  min-height: 0.45rem;
}
div.sth .sth-panel .sth-panel-header.declaration-panel span {
  color: #6495ed;
}
div.sth .sth-panel .sth-panel-header.declaration-panel .icon {
  display: block;
  height: 0.45rem;
  width: 0.2rem;
  float: left;
  margin-right: 0.1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.2rem;
}
div.sth .sth-panel .sth-panel-header.declaration-panel .icon.icon-select1 {
  background-image: url("../../../images/mobile/icon_select_1.png");
}
div.sth .sth-panel .sth-panel-header.declaration-panel .icon.icon-select2 {
  background-image: url("../../../images/mobile/select_2.png");
}
div.sth .sth-panel .sth-panel-header.resize-80 {
  font-size: 0.16rem;
  font-weight: normal;
  line-height: 0.45rem;
  height: 0.45rem;
}
div.sth .sth-panel .sth-panel-header.resize-80 .collapsible-btn {
  line-height: 0.45rem;
  height: 0.45rem;
}
div.sth .sth-panel .sth-panel-header.resize-80 .sth-toggle {
  float: right;
  margin-top: 0.075rem;
}
div.sth .sth-panel .sth-panel-header.resize-80 > .sth-label:last-child {
  line-height: 0.45rem;
  height: 0.45rem;
  float: right;
}
div.sth .sth-panel .sth-panel-header.resize-80 .sth-customer-import,
div.sth .sth-panel .sth-panel-header.resize-80 > .sth-ocr-import {
  float: right;
  color: #bb1c22;
  font-size: 0.14rem;
  padding: 0 0.02rem;
  height: 0.3rem;
  line-height: 0.3rem;
  text-align: center;
  border: 0.01rem solid #cdcdcd;
  border-color: #bb1c22;
  border-radius: 4px;
  margin-left: 0.1rem;
  margin-top: 0.075rem;
  position: relative;
}
div.sth .sth-panel .sth-panel-header.resize-80 .sth-customer-import .upload-input,
div.sth .sth-panel .sth-panel-header.resize-80 > .sth-ocr-import .upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}
div.sth .sth-panel .sth-panel-header.resize-80 .sth-customer-import span,
div.sth .sth-panel .sth-panel-header.resize-80 > .sth-ocr-import span {
  height: 100%;
}
div.sth .sth-panel .sth-panel-header.resize-80 .icon {
  float: right;
  width: 0.2rem;
  height: 0.2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.2rem;
  margin-top: 0.125rem;
}
div.sth .sth-panel .sth-panel-header.resize-80 .icon.icon-close {
  background-image: url("../../../images/mobile/icon_close.png");
}
div.sth .sth-panel .sth-panel-header.currency-header > .sth-label:last-child {
  color: #bb1c22;
}
div.sth .sth-panel .sth-panel-header.collapsible hr {
  opacity: 0;
}
div.sth .sth-panel .sth-panel-header.collapsible .collapsible-btn {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  transform: rotate(180deg);
}
div.sth .sth-panel .sth-panel-header.collapsible.expanded hr {
  opacity: 1;
}
div.sth .sth-panel .sth-panel-header.collapsible.expanded .collapsible-btn {
  transform: rotate(0);
}
div.sth .sth-panel .sth-panel-header.clause .clause-btn {
  display: inline-block;
  vertical-align: center;
  padding: 0;
  border-radius: 5px;
  border: 1px solid #bb1c22;
  height: 30px;
  width: 0.4rem;
  line-height: 30px;
  background: #ffffff;
  color: #bb1c22;
  font-size: 0.14rem;
  position: absolute;
  top: 7px;
  margin-left: 4%;
}
div.sth .sth-panel .sth-panel-header.clause .clause-btn .sth-single-step-in-row {
  height: 25px;
  line-height: 25px;
  color: #333333;
}
div.sth .sth-panel .sth-panel-header.clause .sth-step-in-row:after {
  display: none;
}
div.sth .sth-panel .sth-panel-header.clause .sth-container-body {
  text-align: left;
}
div.sth .sth-panel.cells .sth-panel-body > .sth-cell:not(:last-child) {
  margin-bottom: 5px;
}
div.sth .sth-array-panel > .sth-panel.sth-array-panel-header {
  margin-bottom: 0;
}
div.sth .sth-array-panel > .sth-panel.sth-in-array-panel {
  box-shadow: none;
  padding: 0;
  margin-bottom: 0.1rem;
}
div.sth .sth-array-panel > .sth-panel.sth-in-array-panel:not(:last-child) {
  border-bottom: 1px solid #eeeeee;
}
div.sth .sth-array-panel > .sth-panel.sth-in-array-panel > .sth-panel-header {
  display: none;
}
div.sth .sth-product-info-header {
  display: block;
  position: relative;
  background-color: #ffffff;
  padding: 0 0.15rem;
  margin-bottom: 0.1rem;
  box-sizing: border-box;
  padding: 0.12rem 0.15rem;
  border-top: 0;
  height: 0.6rem;
}
div.sth .sth-product-info-header:before {
  content: "";
  display: table;
}
div.sth .sth-product-info-header:after {
  content: "";
  display: table;
  clear: both;
}
div.sth .sth-product-info-header > div {
  display: block;
  position: relative;
  float: left;
}
div.sth .sth-product-info-header .sth-product-info-header-name {
  width: 63%;
}
div.sth .sth-product-info-header .sth-product-info-header-name span {
  display: block;
  color: #74b5e4;
  font-size: 0.12rem;
}
div.sth .sth-product-info-header .sth-product-vendor-logo {
  position: absolute;
  right: 0.15rem;
  width: 1.12rem;
  height: 0.35rem;
}
div.sth .sth-product-info-header .sth-product-vendor-logo > img {
  width: 100%;
  height: 100%;
}
div.sth .sth-product-info-header .sth-product-info-liked {
  width: 100px;
  height: 0.3rem;
  padding: 2px 10px;
  text-align: right;
  font-size: 0.12rem;
  line-height: 0.3rem;
  color: #999999;
}
div.sth .sth-product-info-header .sth-product-info-liked.liked {
  color: #bb1c22;
}
div.sth .sth-product-info-header .sth-product-info-liked span:first-child {
  margin-right: 5px;
}
div.sth .sth-quote-conditions {
  display: block;
  position: relative;
}
div.sth .sth-quote-conditions .sth-icon-labels {
  line-height: 0.45rem;
}
div.sth .sth-quote-conditions .sth-icon-labels .sth-icon-labels-icon {
  color: #bb1c22;
  line-height: 0.45rem;
}
div.sth .sth-quote-conditions .sth-icon-labels .sth-icon-labels-label:nth-child(2) {
  color: #666666;
}
div.sth .sth-quote-conditions .sth-icon-labels .sth-icon-labels-label:nth-child(2):after {
  content: ":";
  width: 10px;
  display: block;
  float: right;
}
div.sth .sth-quote-conditions .sth-icon-labels .sth-icon-labels-label:not(:nth-child(2)) {
  color: #999999;
}
div.sth .sth-guard-points {
  display: block;
  position: relative;
}
div.sth .sth-guard-points:before {
  content: "";
  display: table;
}
div.sth .sth-guard-points:after {
  content: "";
  display: table;
  clear: both;
}
div.sth .sth-guard-points .sth-icon-labels {
  height: 0.45rem;
  line-height: 0.45rem;
  float: left;
  min-width: 50%;
}
div.sth .sth-guard-points .sth-icon-labels .sth-icon-labels-icon {
  color: #bb1c22;
  line-height: 0.45rem;
}
div.sth .sth-duty {
  display: block;
  position: relative;
}
div.sth .sth-duty .sth-panel-header {
  font-size: 0.14rem;
  color: #666666;
  text-align: left;
  padding: 0 0.15rem;
}
div.sth .sth-duty .sth-panel-header.collapsible hr {
  border-top-width: 1px;
}
div.sth .sth-duty .sth-panel-header .collapsible-btn.fa-fw {
  font-size: 0.2rem;
}
div.sth .sth-duty .sth-container-body .sth-label {
  font-size: 0.12rem;
  color: #999999;
  line-height: 0.28rem;
}
div.sth .sth-duties {
  display: block;
  position: relative;
}
div.sth .sth-duties .sth-duty:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
}
div.sth .sth-advice-to .sth-container-body:before {
  content: "";
  display: table;
}
div.sth .sth-advice-to .sth-container-body:after {
  content: "";
  display: table;
  clear: both;
}
div.sth .sth-advice-to .sth-container-body .sth-label {
  float: left;
  width: 30%;
}
div.sth .sth-advice-to .sth-container-body .sth-text {
  float: left;
  width: 30%;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #d5d5d5;
}
div.sth .sth-advice-to .sth-container-body .sth-toggle {
  float: right;
  width: 100px;
  margin-top: 0.075rem;
}
div.sth .sth-additional-coverages {
  display: block;
  position: relative;
}
div.sth .sth-additional-coverages .sth-additional-coverage {
  border-bottom: 1px solid #eeeeee;
  padding: 0;
  box-shadow: none;
}
div.sth .sth-additional-coverages .sth-additional-coverage .sth-panel-header hr {
  border-width: 1px;
  border-color: #eeeeee;
}
div.sth .sth-image {
  width: 100%;
}
div.sth .sth-legalIndiId-toggle {
  float: right;
  margin-right: 0.1rem;
}
div.sth .sth-legalIndiId-toggle .sth-legalIndiId-toggle-label {
  float: left;
  margin-right: 0.1rem;
  color: #999999;
}
div.sth .sth-import-customer {
  position: fixed;
  left: -100vw;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  overflow-y: auto;
  height: 100%;
  z-index: 1050;
  background: #fff;
}
div.sth .sth-import-customer .page-body {
  padding-top: 0;
  position: relative;
}
div.sth .sth-import-customer .page-body .customer-index-wrap {
  width: 0.14rem;
  text-align: center;
  position: absolute;
  color: #333333;
  right: 0.05rem;
  top: 20vh;
  height: 80vh;
  overflow-y: scroll;
}
div.sth .sth-import-customer .page-body .customer-index-wrap .customer-index {
  height: 0.14rem;
  line-height: 0.14rem;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 0.05rem;
  font-weight: 600;
  font-size: 0.1rem;
}
div.sth .sth-import-customer .page-body .customer-index-wrap .customer-index.active {
  background: #bb1c22;
  color: #ffffff;
}
div.sth .sth-import-customer .page-body .customer-list-header {
  box-sizing: border-box;
  border-bottom: 1px solid #e2e2e2;
  height: 0.45rem;
  line-height: 0.45rem;
  background: #ffffff;
  color: #666666;
}
div.sth .sth-import-customer .page-body .customer-list-header span {
  width: 33%;
  height: 100%;
  float: left;
  color: #666666;
  box-sizing: border-box;
  text-align: center;
}
div.sth .sth-import-customer .page-body .customer-list-header span.active {
  color: #bb1c22;
  border-bottom: 0.02rem solid #bb1c22;
}
div.sth .sth-import-customer .page-body .customer-group-title {
  padding: 0 0.15rem;
  height: 0.2rem;
  line-height: 0.2rem;
  background: #f4f4f4;
  text-align: left;
  color: #333333;
}
div.sth .sth-import-customer .page-body .customer-group-content {
  padding: 0 0.15rem;
  background: #ffffff;
}
div.sth .sth-import-customer .page-body .customer-group-content .customer-item {
  height: 0.45rem;
  line-height: 0.45rem;
  border-bottom: 1px solid #e2e2e2;
  text-align: left;
  color: #333333;
}
div.sth .sth-import-customer .page-body .customer-group-content .customer-item:last-child {
  border: none;
}
div.sth .sth-ocr-import .page-body {
  padding-top: 0;
}
div.sth .sth-ocr-import .panel {
  margin: 0.1rem 0;
  border-bottom: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
  background: #ffffff;
}
div.sth .sth-ocr-import .panel .font-color-red {
  color: #bb1c22;
}
div.sth .sth-ocr-import .panel .header {
  height: 0.45rem;
  line-height: 0.45rem;
  padding-left: 0.15rem;
  border-bottom: 1px solid #e2e2e2;
}
div.sth .sth-ocr-import .panel .header.no-border {
  border: 0;
}
div.sth .sth-ocr-import .panel .header .icon {
  display: block;
  height: 100%;
  width: 0.2rem;
  float: left;
  margin-right: 0.1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.2rem;
}
div.sth .sth-ocr-import .panel .header .icon.icon-person-infor {
  background-image: url("../../../images/mobile/icon_person_infor.png");
}
div.sth .sth-ocr-import .panel .header .icon.icon-identification {
  background-image: url("../../../images/mobile/icon_identification.png");
}
div.sth .sth-ocr-import .panel .content {
  padding: 0 0.15rem;
}
div.sth .sth-ocr-import .panel .content ul {
  padding: 0 0 0.15rem;
}
div.sth .sth-ocr-import .panel .content ul li {
  list-style: decimal;
  margin-left: 0.15rem;
}
div.sth .sth-ocr-import .panel .content.padding-top {
  padding-top: 0.15rem;
}
div.sth .sth-ocr-import .panel .content.tips {
  font-size: 0.12rem;
}
div.sth .sth-ocr-import .panel .content .tip {
  margin: 0.05rem 0;
  font-size: 0.12rem;
  color: #666666;
}
div.sth .sth-ocr-import .panel .content .process {
  display: flex;
}
div.sth .sth-ocr-import .panel .content .process div {
  flex: 1;
  height: 0.6rem;
  text-align: center;
  color: #666666;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
div.sth .sth-ocr-import .panel .content .process div.active {
  color: #bb1c22;
}
div.sth .sth-ocr-import .panel .content .process div.active span {
  color: #bb1c22;
  border-color: #bb1c22;
}
div.sth .sth-ocr-import .panel .content .process div.active::before {
  background: #bb1c22;
}
div.sth .sth-ocr-import .panel .content .process div.active::after {
  background: #bb1c22;
}
div.sth .sth-ocr-import .panel .content .process div span {
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  line-height: 0.25rem;
  border-radius: 50%;
  border: 0.02rem solid #999999;
  color: #999999;
}
div.sth .sth-ocr-import .panel .content .process div p {
  text-align: center;
  margin: 0.1rem 0 0;
}
div.sth .sth-ocr-import .panel .content .process div::before {
  content: "";
  display: block;
  height: 0.02rem;
  width: 50%;
  position: absolute;
  top: 25%;
  left: -0.15rem;
  background: #999999;
}
div.sth .sth-ocr-import .panel .content .process div::after {
  content: "";
  display: block;
  height: 0.02rem;
  width: 50%;
  position: absolute;
  top: 25%;
  right: -0.15rem;
  background: #999999;
}
div.sth .sth-ocr-import .panel .content .process div:first-child::before {
  display: none;
}
div.sth .sth-ocr-import .panel .content .process div:last-child::after {
  display: none;
}
div.sth .sth-ocr-import .panel .content .bottom {
  height: 0.45rem;
  line-height: 0.45rem;
  padding-right: 0.15rem;
  color: #666666;
}
div.sth .sth-ocr-import .panel .content .bottom span {
  float: right;
  color: #333333;
}
div.sth .sth-ocr-import .panel .content .bottom .icon {
  display: block;
  height: 100%;
  width: 0.2rem;
  float: right;
  margin-right: 0.1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.2rem;
}
div.sth .sth-ocr-import .panel .content .bottom .icon.icon-sample {
  background-image: url("../../../images/mobile/icon_video.png");
}
div.sth .sth-ocr-import .panel .upload {
  padding: 0.15rem;
}
div.sth .sth-ocr-import .panel .upload .upload-container {
  margin-bottom: 0.1rem;
  position: relative;
}
div.sth .sth-ocr-import .panel .upload .upload-container .delete {
  height: 0.2rem;
  width: 0.2rem;
  background: url("../../../images/mobile/icon_close.png") center no-repeat;
  background-size: 0.2rem;
  position: absolute;
  top: -0.1rem;
  right: -0.1rem;
  z-index: 49;
}
div.sth .sth-ocr-import .panel .upload .upload-container .img-container {
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  border: 0.005rem dashed #e2e2e2;
  background: #f2f2f2;
  color: #999999;
  position: relative;
  overflow: hidden;
}
div.sth .sth-ocr-import .panel .upload .upload-container .img-container img {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  object-fit: contain;
  margin: -1px;
  position: absolute;
  left: 0;
  top: 0;
}
div.sth .sth-ocr-import .panel .upload .upload-container .img-container input {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
div.sth .sth-ocr-import .panel .upload .upload-container p {
  margin: 0;
  text-align: center;
  color: #999999;
  padding: 0.1rem 0;
}
div.sth .sth-ocr-import .panel .form {
  padding: 0 0.15rem;
  margin-bottom: 0.15rem;
}
div.sth .sth-ocr-import .panel .form .text-container {
  height: 0.45rem;
  display: flex;
  margin-bottom: 0.1rem;
}
div.sth .sth-ocr-import .panel .form .text-container:first-child {
  margin-top: 0.1rem;
}
div.sth .sth-ocr-import .panel .form .text-container input {
  width: 100%;
}
div.sth .sth-ocr-import .panel .form .text-container select {
  width: 100%;
}
div.sth .sth-ocr-import .panel .form .text-container .label {
  line-height: 0.45rem;
  flex: 3;
}
div.sth .sth-ocr-import .panel .form .text-container .input-container {
  flex: 7;
  height: 0.45rem;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding-left: 0.05rem;
}
div.sth .sth-ocr-import .panel .form .text-container .date-age-contaner {
  flex: 7;
  height: 0.45rem;
  display: flex;
}
div.sth .sth-ocr-import .panel .form .text-container .date-age-contaner .age-container {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding-left: 0.05rem;
  flex: 2;
  margin-right: 0.1rem;
}
div.sth .sth-ocr-import .panel .form .text-container .date-age-contaner .date-container {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding-left: 0.05rem;
  flex: 3;
}
div.sth .sth-ocr-import .panel .form .text-container .gender-container {
  flex: 7;
  height: 0.45rem;
  display: flex;
}
div.sth .sth-ocr-import .panel .form .text-container .gender-container div {
  width: 100%;
  flex: 1;
}
div.sth .sth-ocr-import .panel .form .text-container .gender-container div label {
  display: block;
  height: 0.45rem;
  width: 0.2rem;
  float: left;
  margin-right: 0.1rem;
  background-repeat: no-repeat;
  background-position: left;
  background-size: 0.2rem;
  background-image: url("../../../images/mobile/radio_1.png");
}
div.sth .sth-ocr-import .panel .form .text-container .gender-container div label.active {
  background-image: url("../../../images/mobile/radio_2.png");
}
div.sth .sth-ocr-import .panel .form .text-container .gender-container div span {
  height: 0.45rem;
  line-height: 0.45rem;
}
div.sth .sth-benefit-presentation .rang-container {
  width: 100%;
  position: relative;
  height: 48px;
  white-space: nowrap;
}
div.sth .sth-benefit-presentation .rang-container .handle,
div.sth .sth-benefit-presentation .rang-container input {
  float: left;
}
div.sth .sth-benefit-presentation .rang-container .handle {
  width: 8%;
  text-align: center;
}
div.sth .sth-benefit-presentation .rang-container .handle .icon {
  display: block;
  height: 0.45rem;
  width: 0.2rem;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.2rem;
}
div.sth .sth-benefit-presentation .rang-container .handle .icon-plus {
  background-image: url("../../../images/mobile/icon_plus.png");
}
div.sth .sth-benefit-presentation .rang-container .handle .icon-minus {
  background-image: url("../../../images/mobile/icon_minus.png");
}
div.sth .sth-benefit-presentation .rang-container .tip {
  top: 15px;
  left: 33px;
  height: 20px;
  width: 20px;
  position: absolute;
  background: #000;
  border-radius: 50%;
  color: #fff;
  background: #bb1c22;
  display: block;
  text-align: center;
  line-height: 20px;
}
div.sth .sth-benefit-presentation .selection {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.sth .sth-benefit-presentation .selection select {
  width: 60px;
  padding: 0;
  height: 26px;
  text-align: center;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
}
div.sth .sth-benefit-presentation .content div {
  line-height: 2;
}
div.sth .sth-benefit-presentation .content span {
  line-height: 2;
  float: right;
}
div.sth .sth-benefit-presentation input[type=range] {
  -webkit-appearance: none;
  width: 80%;
}
div.sth .sth-benefit-presentation input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  margin-top: -5px;
  /*使滑块超出轨道部分的偏移量相等*/
  background: #bb1c22;
  border-radius: 50%;
  /*外观设置为圆形*/
  border: solid 0.1em rgba(205, 224, 230, 0.5);
  /*设置边框*/
}
div.sth .sth-benefit-presentation input[type=range]::-webkit-slider-runnable-track {
  height: 1px;
  border-radius: 10px;
  background: #666;
  /*将轨道设为圆角的*/
}
div.sth .sth-benefit-presentation input[type=range]:focus {
  outline: none;
}
div.sth .sth-declares .sth-declara-toggle-remark {
  background: #ffffff;
  padding: 0.075rem 0.15rem;
  margin-bottom: 0.1rem;
  font-size: 0.14rem;
  color: #333333;
}
div.sth .sth-declares .sth-declara-toggle-remark .sth-delara-warp {
  position: relative;
  height: 100%;
  width: 100%;
}
div.sth .sth-declares .sth-declara-toggle-remark .sth-declara-label {
  width: 77.333%;
}
div.sth .sth-declares .sth-declara-toggle-remark .sth-declara-toggle {
  position: absolute;
  top: calc(50% - 0.145rem);
  right: 0.15rem;
}
div.sth .sth-declares .sth-declara-toggle-remark .sth-declara-area {
  width: 100%;
  margin-top: 0.1rem;
}
div.sth .sth-declares .sth-declara-input-wrap {
  width: 100%;
}
div.sth .sth-declares .sth-declara-input-wrap .sth-declara-input-item {
  width: 100%;
  margin-top: 0.1rem;
  clear: both;
}
div.sth .sth-declares .sth-declara-input-wrap .sth-declara-input-item .sth-declara-input-name {
  width: 35%;
  float: left;
}
div.sth .sth-declares .sth-declara-input-wrap .sth-declara-input-item .sth-declara-input-name2 {
  width: 88%;
  float: left;
}
div.sth .sth-declares .sth-declara-input-wrap .sth-declara-input-item .sth-declara-input-content {
  width: 65%;
  border: 0.01rem solid #cdcdcd;
  border-radius: 2px;
  height: 0.45rem;
  padding: 0.01rem 0.025rem;
}
div.sth .sth-declares .sth-declara-input-wrap .sth-declara-input-item .sth-declara-radio-point {
  width: 25px;
  height: 25px;
  float: right;
}
div.sth .sth-declares .sth-declara-radio {
  background: #ffffff;
  padding: 0.075rem 0.15rem;
  margin-bottom: 0.1rem;
}
div.sth .sth-declares .sth-declara-radio .sth-radio-warp {
  display: flex;
  flex-flow: row wrap;
}
div.sth .sth-declares .sth-declara-radio .sth-radio-warp .sth-declara-radio-warp {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
}
div.sth .sth-declares .sth-declara-radio .sth-radio-warp .sth-declara-radio-warp .sth-declara-radio-point {
  width: 20px;
  height: 20px;
}
div.sth .sth-declares .sth-declara-radio .sth-radio-warp .sth-declara-radio-warp .sth-radio-remark-hide {
  display: none;
}
div.sth .sth-declares .sth-declara-radio .sth-radio-warp .sth-declara-radio-warp .sth-radio-remark-show {
  width: 100%;
  display: flex;
  margin: 10px;
}
div.sth .sth-declares .sth-declara-radio .sth-radio-warp .sth-declara-radio-warp .sth-radio-remark-show .sth-radio-remark-name {
  line-height: 30px;
  width: 25%;
}
div.sth .sth-declares .sth-declara-radio .sth-radio-warp .sth-declara-radio-warp .sth-radio-remark-show .sth-radio-remark-input {
  border: solid 1px;
  width: 75%;
  height: 30px;
}
div.sth .sth-declares .sth-declara-parent {
  background: #ffffff;
  padding: 0 0.15rem;
}
div.sth .sth-declares .children-of-parent {
  margin-bottom: 0;
}
div.sth .sth-declara-show {
  background: #ffffff;
  padding: 0.075rem 0.15rem;
  margin-bottom: 0.1rem;
  font-size: 0.14rem;
  color: #333333;
}
div.sth .sth-declara-show .sth-declara-show-warp {
  position: relative;
  height: 100%;
  width: 100%;
}
div.sth .sth-declara-show .sth-declara-show-label {
  width: 77.333%;
  color: #666666;
}
div.sth .sth-declara-show .sth-declara-show-confirm {
  position: absolute;
  top: calc(50% - 0.09rem);
  right: 0.15rem;
}
div.sth .sth-declara-show .sth-declara-show-remark {
  width: 90%;
  margin-top: 0.1rem;
  word-wrap: break-word;
}
div.sth .sth-declara-show .sth-declare-special-item {
  width: 100%;
  display: table;
  margin-top: 0.1rem;
}
div.sth .sth-declara-show .sth-declare-special-item .sth-declare-special-label {
  width: 50%;
  display: table-cell;
  padding-right: 0.1rem;
}
div.sth .sth-declara-show .sth-declare-special-item .sth-declare-special-remark {
  width: 50%;
  display: table-cell;
}
div.sth .sth-label-text {
  display: flex;
}
div.sth .sth-label-text .sth-label-name {
  width: 35%;
  line-height: 0.45rem;
  color: #666666;
}
div.sth .sth-label-text .sth-label-value {
  line-height: 0.45rem;
  color: #333333;
  width: 65%;
}
div.sth .sth-label-text.sth-one-line-label-text {
  position: relative;
  background-color: #ffffff;
  padding: 0 0.15rem;
  margin-bottom: 0.1rem;
  box-sizing: border-box;
}
div.sth .sth-label-text-r {
  text-align: right;
}
div.sth .sth-label-text-r .sth-label-name {
  color: #666666;
  display: inline-block;
}
div.sth .sth-label-text-r .sth-label-value {
  color: #bb1c22;
  display: inline-block;
}
div.sth .sth-add-beneficiary {
  display: block;
  height: 0.45rem;
  text-align: center;
  line-height: 0.45rem;
  font-size: 0.14rem;
  color: #bb1c22;
  margin-bottom: 0.15rem;
  background: #ffffff;
  border-top: 1px solid #e2e2e2;
  width: 100%;
}
div.sth.sth-desk-default {
  background: transparent;
  padding-bottom: 0;
}
div.sth.sth-desk-default.sth-desk-default-view input[disabled],
div.sth.sth-desk-default.sth-desk-default-view select[disabled] {
  opacity: 1;
}
div.sth.sth-desk-default .sth-panel .sth-panel-body:before {
  content: "";
  display: table;
}
div.sth.sth-desk-default .sth-panel .sth-panel-body:after {
  content: "";
  display: table;
  clear: both;
}
div.sth.sth-desk-default .sth-panel .sth-panel-body .sth-cell {
  width: 25%;
  float: left;
  margin-bottom: 5px;
}
div.sth.sth-desk-default .sth-panel .sth-panel-body .sth-cell .sth-label {
  padding-left: 5px;
}
div.sth.sth-desk-default .sth-panel .sth-panel-body .sth-cell.sth-cell-2 {
  width: 50%;
}
div.sth.sth-desk-default .sth-panel .sth-panel-body .sth-cell.sth-cell-2 .sth-label {
  width: 17.5%;
}
div.sth.sth-desk-default .sth-panel .sth-panel-body .sth-cell.sth-cell-2 .sth-label + * {
  width: 82.5%;
}
div.sth.sth-desk-default .sth-panel .sth-panel-body .sth-cell.sth-cell-3 {
  width: 75%;
}
div.sth.sth-desk-default .sth-panel .sth-panel-body .sth-cell.sth-cell-3 .sth-label {
  width: 11.6666666667%;
}
div.sth.sth-desk-default .sth-panel .sth-panel-body .sth-cell.sth-cell-3 .sth-label + * {
  width: 88.3333333333%;
}
div.sth.sth-desk-default .sth-panel .sth-panel-body .sth-cell.sth-cell-4 {
  width: 100%;
}
div.sth.sth-desk-default .sth-panel .sth-panel-body .sth-cell.sth-cell-4 .sth-label {
  width: 8.75%;
}
div.sth.sth-desk-default .sth-panel .sth-panel-body .sth-cell.sth-cell-4 .sth-label + * {
  width: 91.25%;
}
div.sth.sth-desk-default .sth-panel .sth-panel-body .sth-cell.sth-cell-row-grabbed + * {
  clear: both;
}
div.sth .sth-wizard {
  overflow-x: hidden;
  overflow-y: scroll;
}
div.sth .sth-wizard .page {
  transition: transform 400ms;
  display: block;
}
div.sth .sth-wizard .page > .page-body {
  padding-top: 44px;
  padding-bottom: 48px;
}
div.sth .sth-wizard .page .noheader {
  padding-top: 0;
}
div.sth .sth-wizard .page.prev-page {
  transform: translate(-100%, 0%);
  display: none;
}
div.sth .sth-wizard .page.current-page {
  transform: translate(0%, 0%);
  display: block;
}
div.sth .sth-wizard .page.next-page {
  transform: translate(100%, 0%);
  display: none;
}
div.sth .sth-wizard .page.hide-page {
  display: none;
}
div.sth .sth-wizard .page .page-footer {
  display: flex;
}
div.sth .sth-wizard .page .page-footer .wizard-step-bottom-button {
  flex: 1;
  text-align: center;
  line-height: 48px;
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.258824);
  background: #ffffff;
  color: #bb1c22;
  font-weight: 600;
}

.statement {
  background: #ffffff;
  padding: 0 0.15rem;
}
.statement .statement-title {
  height: 0.45rem;
  line-height: 0.45rem;
  text-align: center;
  color: #bb1c22;
  border-bottom: 1px solid #e2e2e2;
}
.statement .statement-content {
  color: #333333;
  line-height: 0.22rem;
  padding: 0.1rem 0;
}
.statement .statement-content-t {
  line-height: 0.3rem;
}
.statement .read {
  width: 0.15rem;
  height: 0.15rem;
  display: inline-block;
  background: url("../../../images/mobile/checkbox_1.png") no-repeat;
  background-size: cover;
  vertical-align: middle;
  margin-right: 0.05rem;
}
.statement .read-ck {
  background: url("../../../images/mobile/checkbox_2.png") no-repeat;
  background-size: cover;
}
.statement #read-ck {
  background: url("../../../images/mobile/checkbox_2.png") no-repeat;
  background-size: cover;
}

.underwriting-container {
  font-size: 0.14rem;
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.underwriting-container .underwriting-fail {
  width: 100%;
  padding: 0.15rem;
}
.underwriting-container .title {
  line-height: 0.3rem;
  margin-bottom: 0.1rem;
}
.underwriting-container .promptIcon {
  width: 0.2rem;
  height: 0.2rem;
  display: inline-block;
  margin: 5px 0.1rem 0 0;
  float: left;
  background: url("../../../images/mobile/icon_notice.png") no-repeat;
  background-size: cover;
}
.underwriting-container .sucIcon {
  width: 0.2rem;
  height: 0.2rem;
  display: inline-block;
  margin: 5px 0.1rem 0 0;
  float: left;
  background: url("../../../images/mobile/icon_success.png") no-repeat;
  background-size: cover;
}
.underwriting-container .failCause {
  border: 1px solid #e2e2e2;
  padding: 0.1rem;
  color: #666666;
  word-break: break-all;
}
.underwriting-container .failCause .causeItem {
  line-height: 0.22rem;
  margin: 0;
}
.underwriting-container .failCause .checkItem {
  line-height: 0.22rem;
  margin: 0 0 0.1rem;
}
.underwriting-container .radio {
  width: 0.21rem;
  height: 0.21rem;
  display: inline-block;
  float: left;
  margin-right: 0.1rem;
  background: url("../../../images/mobile/radio_1.png") no-repeat;
  background-size: cover;
}
.underwriting-container .radio-ck {
  width: 0.21rem;
  height: 0.21rem;
  background: url("../../../images/mobile/radio_2.png") no-repeat;
  background-size: cover;
}
.underwriting-container .btns-container {
  display: flex;
  width: 100%;
  flex-flow: row;
  margin-top: 0.3rem;
}
.underwriting-container .btns-container .btn-con {
  flex: 2.5;
}
.underwriting-container .btns-container .btn {
  width: -webkit-calc(100% - 0.1rem * 2);
  height: 0.3rem;
  line-height: 0.3rem;
  border: 1px solid #bb1c22;
  border-radius: 4px;
  text-align: center;
  margin: 0 auto;
}
.underwriting-container .btns-container .btn1 {
  color: #bb1c22;
  background: #ffeaea;
}
.underwriting-container .btns-container .btn2 {
  color: #ffffff;
  background: #bb1c22;
}
.underwriting-container .btns-container .enable .btn {
  color: #ffffff;
  background: #999999;
  border: none;
}
.underwriting-container .status-container2 {
  text-align: center;
  line-height: 0.22rem;
  overflow: hidden;
}
.underwriting-container .statusImg {
  width: 0.68rem;
  height: 0.68rem;
  margin: 0.4rem auto 0.2rem;
  background: url("../../../images/mobile/icon_success_2.png") no-repeat;
  background-size: cover;
}
.underwriting-container .msg-p {
  margin: 0;
}
.underwriting-container .status-container2 .btnBack {
  width: calc(100% - 0.15rem * 2);
  height: 0.3rem;
  line-height: 0.3rem;
  background: #bb1c22;
  color: #ffffff;
  text-align: center;
  margin: 0.2rem auto;
  border-radius: 4px;
}

.status-container .statusImg {
  width: 0.68rem;
  height: 0.68rem;
  margin: 0.4rem auto 0.2rem;
  background: url("../../../images/mobile/icon_success_2.png") no-repeat;
  background-size: cover;
}
.status-container .statusImgErr {
  width: 0.68rem;
  height: 0.68rem;
  margin: 0.4rem auto 0.2rem;
  background: url("../../../images/mobile/icon_fail.png") no-repeat;
  background-size: cover;
}
.status-container .mainMsg {
  margin: 0;
  color: #333333;
  text-align: center;
  font-size: 0.17rem;
}
.status-container .mainDesc {
  margin: 0;
  color: #666666;
  text-align: center;
  font-size: 0.14rem;
  margin-top: 0.1rem;
}

.uploadPrompt {
  background: #ffffff;
  padding: 0.15rem;
  margin-bottom: 0.1rem;
}
.uploadPrompt .prompt-title {
  height: 0.45rem;
  line-height: 0.45rem;
  color: #bb1c22;
  font-size: 0.17rem;
}
.uploadPrompt .prompt-content {
  line-height: 0.22rem;
  color: #333333;
}

.sth-document-item-show {
  width: 100%;
}
.sth-document-item-show .sth-document-image-show {
  width: 100%;
  min-height: 1.8rem;
  border: 0.01rem dashed #cdcdcd;
  padding: 0.1rem 0.15rem;
}
.sth-document-item-show .sth-document-image-show > img {
  width: 100%;
}
.sth-document-item-show .sth-document-title-show {
  width: 100%;
  text-align: center;
  color: #999999;
  margin-top: 0.05rem;
}

.sth-uploadItem {
  margin-top: 0.1rem;
}
.sth-uploadItem .content {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  color: #999999;
  background-color: #f2f2f2;
  border: 0.01rem dashed #cdcdcd;
  position: relative;
}
.sth-uploadItem .uploadArea {
  position: relative;
}
.sth-uploadItem .uploadArea .icon-content {
  position: absolute;
  width: 0.5rem;
  top: -0.1rem;
  right: -0.1rem;
}
.sth-uploadItem .uploadArea .icon {
  float: right;
  width: 0.2rem;
  height: 0.2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.2rem;
}
.sth-uploadItem .uploadArea .icon.icon-close {
  background-image: url("../../../images/mobile/icon_close.png");
}
.sth-uploadItem .upload-img {
  width: 100%;
  height: 100%;
  border: 0.01rem dashed #cdcdcd;
}
.sth-uploadItem .upload-img .img-content {
  width: calc(100% - 0.15rem * 2);
  margin: 0 auto;
}
.sth-uploadItem .upload-img .img-content img {
  width: 100%;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  border: none;
}
.sth-uploadItem .upload-title {
  width: 100%;
  text-align: center;
  color: #999999;
}
.sth-uploadItem .existImg .content {
  display: none;
}
.sth-uploadItem .existImg .upload-img {
  display: block;
}
.sth-uploadItem .noImg .content {
  display: block;
}
.sth-uploadItem .noImg .upload-img {
  display: none;
}
.sth-uploadItem .camera {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  opacity: 0;
}

.sth-add-other-image {
  display: inline-block;
  width: 100%;
  height: 0.45rem;
  line-height: 0.45rem;
  text-align: center;
  color: #bb1c22;
  border-top: 1px solid #e2e2e2;
  margin-bottom: 0.1rem;
  font-size: 0.14rem;
  background-color: #ffffff;
  margin-top: -0.1rem;
}

.sth-share-button-container {
  padding: 0.1rem;
  background-color: #eeeeee;
  overflow: hidden;
}
.sth-share-button-container .share-button {
  width: 1rem;
  height: 0.3rem;
  line-height: 0.3rem;
  background-color: #999999;
  color: #ffffff;
  border-radius: 4px;
  text-align: center;
  float: right;
  font-size: 0.14rem;
  display: block;
}
.sth-share-button-container .share-ok {
  background-color: #bb1c22;
}
.sth-share-button-container .button-hidden {
  display: none;
}

.share-button-container-hidden {
  display: none;
}

#hintContent input {
  display: inline-block;
  height: 13px;
}

.sth-document-signature-item {
  padding: 0.1rem;
  background-color: #ffffff;
  border-bottom: 1px solid #e2e2e2;
  color: #333333;
}
.sth-document-signature-item .signature {
  width: 0.15rem;
  height: 0.15rem;
  display: inline-block;
  background: url("../../../images/mobile/checkbox_1.png") no-repeat;
  background-size: cover;
  vertical-align: middle;
  margin-right: 0.05rem;
}
.sth-document-signature-item .signature-ck {
  background: url("../../../images/mobile/checkbox_2.png") no-repeat;
  background-size: cover;
}
.sth-document-signature-item .showInput {
  display: inline-block;
}
.sth-document-signature-item .hideInput {
  display: none;
}
.sth-document-signature-item .to-document {
  overflow: hidden;
  float: right;
  width: 0.1rem;
  height: 0.17rem;
  background: url("../../../images/mobile/arrow_right.png") no-repeat;
  background-size: cover;
  vertical-align: middle;
}

.signature-item-hidden {
  display: none;
}

.submitSignSuccess-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.submitSignSuccess-container .image-container {
  text-align: center;
  padding-top: 0.4rem;
}
.submitSignSuccess-container .image-container .icon {
  display: inline-block;
  width: 0.68rem;
  height: 0.68rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.68rem;
}
.submitSignSuccess-container .image-container .icon.icon-success-2 {
  background-image: url("../../../images/mobile/icon_success_2.png");
}
.submitSignSuccess-container .success-text {
  width: 100%;
  text-align: center;
  font-size: 0.17rem;
  margin-top: 0.1rem;
}
.submitSignSuccess-container .success-text .sub-title {
  font-size: 0.12rem;
}
.submitSignSuccess-container .closePage {
  width: calc(100% - 0.15rem * 2);
  height: 0.3rem;
  line-height: 0.3rem;
  margin: 0.3rem auto 0;
  text-align: center;
  border-radius: 4px;
  background: #bb1c22;
  color: #ffffff;
  display: block;
}
.submitSignSuccess-container .hidden {
  display: none;
}

.shareUrlStatus-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.shareUrlStatus-container .image-container {
  text-align: center;
  padding-top: 0.4rem;
}
.shareUrlStatus-container .image-container .icon {
  display: inline-block;
  width: 0.68rem;
  height: 0.68rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.68rem;
}
.shareUrlStatus-container .image-container .icon.icon-fail {
  background-image: url("../../../images/mobile/icon_fail.png");
}
.shareUrlStatus-container .error-text {
  width: 100%;
  text-align: center;
  font-size: 0.17rem;
  margin-top: 0.1rem;
}

.signaturePrompt-content {
  padding: 0.1rem;
  background: #ffffff;
  line-height: 0.22rem;
  margin-top: 0.1rem;
}
.signaturePrompt-content .confirm {
  width: 0.15rem;
  height: 0.15rem;
  display: inline-block;
  background: url("../../../images/mobile/checkbox_1.png") no-repeat;
  background-size: cover;
  vertical-align: middle;
  margin-right: 0.05rem;
}
.signaturePrompt-content .confirm-ck {
  background: url("../../../images/mobile/checkbox_2.png") no-repeat;
  background-size: cover;
}

.signButton {
  display: none;
}
.signButton .signButton-content {
  width: calc(100% - 0.15rem * 2);
  margin: 0.1rem auto 0;
  height: 0.3rem;
  line-height: 0.3rem;
  text-align: center;
  background-color: #6495ed;
  border-radius: 4px;
  font-weight: 700;
  color: #ffffff;
}
.signButton .enable {
  background-color: #999999;
}
.signButton.signButton-show {
  display: block;
}

.btnFacecheck {
  display: block;
  width: calc(100% - 0.15rem * 2);
  height: 0.3rem;
  line-height: 0.3rem;
  background: #bb1c22;
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  margin: 0 auto;
}

#message-check-container {
  width: 100%;
  background: #ffffff;
  margin-top: 0.1rem;
}
#message-check-container .phone {
  border-bottom: 1px solid #e2e2e2;
}
#message-check-container .verifiItem {
  height: 0.45rem;
  line-height: 0.45rem;
  color: #333333;
  width: calc(100% - 0.15rem);
  margin-left: 0.15rem;
  box-sizing: content-box;
}
#message-check-container input {
  border: none;
  margin-left: 0.1rem;
  outline: none;
}
#message-check-container .send-verificode-btn {
  width: 1rem;
  height: 0.3rem;
  line-height: 0.3rem;
  background: #bb1c22;
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  float: right;
  margin-top: 0.07rem;
  margin-right: 0.2rem;
}
#message-check-container .send-verificode-btn.disable {
  background: #999999;
}
#message-check-container .successPrompt {
  height: 0.45rem;
  text-align: center;
  margin-top: 0.2rem;
  display: none;
}
#message-check-container .sucImg {
  display: inline-block;
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.1rem;
  vertical-align: middle;
  background: url("../../../images/mobile/icon_success.png") no-repeat;
  background-size: cover;
}
#message-check-container .variInput {
  width: calc(100% - 195px);
}

#signature-html-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
#signature-html-container #signature-html-main {
  width: 100%;
  height: -webkit-calc(100% - 60px);
  padding: 10px;
  line-height: 20px;
  overflow-y: scroll;
}
#signature-html-container #signature-html-main .fontSize13 {
  font-size: 6px !important;
}
#signature-html-container #signature-html-main .fontSize14 {
  font-size: 7px !important;
}
#signature-html-container #signature-html-main .fontSize15 {
  font-size: 7.5px !important;
}
#signature-html-container #signature-html-main .fontSize16 {
  font-size: 8px !important;
}
#signature-html-container #signature-html-main .fontSize18 {
  font-size: 9px !important;
}
#signature-html-container #signature-html-main #policy_id_t {
  font-size: 10px !important;
}
#signature-html-container .page-content {
  font-size: 0.13rem;
}
#signature-html-container .onlyOneButton {
  margin: 0 auto 15px;
}
#signature-html-container .onlyOneButton .sig-button {
  width: 100%;
  margin-left: 0;
}
#signature-html-container .threeButton .sig-button {
  width: -webkit-calc(30%);
  margin-left: -webkit-calc(3.3%);
}
#signature-html-container .fourButton .sig-button {
  margin-bottom: 15px;
}
#signature-html-container #sig-button {
  width: -webkit-calc(100% - 28px);
  height: 30px;
  margin-bottom: 15px;
}
#signature-html-container .underLine {
  text-decoration: underline;
}
#signature-html-container .sig-button {
  width: -webkit-calc(47%);
  height: 30px;
  text-align: center;
  background: #6495ed;
  float: left;
  margin-left: -webkit-calc(2.5%);
  font-weight: 700;
  font-size: 0.14rem;
  color: #fff;
  border-radius: 4px;
  padding: 0;
  border: none;
}
#signature-html-container #button-container {
  width: -webkit-calc(100% - 28px);
  height: 45px;
  background: #fff;
  box-shadow: 0 5px 5px 0 rgba(231, 231, 231, 0.75);
  border-radius: 4px;
  margin: 15px auto 0;
}
#signature-html-container #button-container .insureOrder-left {
  color: #fff;
  background: #bb1c22;
  font-size: 0.14rem;
  text-align: center;
  float: left;
  border-right: 2px solid white;
  border-radius: 4px;
  width: -webkit-calc(50%);
  height: 45px;
  line-height: 45px;
}
#signature-html-container #button-container .insureOrder-left.enable {
  background: rgb(158, 158, 158);
}
#signature-html-container #button-container .insureOrder-right {
  color: #fff;
  background: #bb1c22;
  font-size: 0.14rem;
  text-align: center;
  float: left;
  border-left: 2px solid white;
  border-radius: 4px;
  width: -webkit-calc(50%);
  height: 45px;
  line-height: 45px;
}
#signature-html-container #button-container .insureOrder-right.enable {
  background: rgb(158, 158, 158);
}
#signature-html-container .enable {
  background: rgb(158, 158, 158);
}
#signature-html-container #backBtn {
  width: calc(100% - 0.15rem * 2);
  height: 45px;
  line-height: 45px;
  margin: 15px auto 0;
  text-align: center;
  background-color: #bb1c22;
  color: #ffffff;
  border-radius: 4px;
}

.payment {
  font-size: 0.125rem;
}
.payment .header .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment .header .title .logo {
  width: 0.375rem;
  flex-basis: 40%;
}
.payment .header .title .timer {
  flex-basis: 60%;
  color: #6b9ef7;
  text-align: right;
}
.payment .header .order-single-info {
  display: flex;
  flex-direction: row;
}
.payment .header .order-single-info .label {
  flex-basis: 30%;
  color: gray;
}
.payment .header .order-single-info .datum {
  flex-basis: 70%;
}
.payment .body .instruction {
  padding: 0.125rem;
  color: gray;
}
.payment .body .bank-selection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment .body .bank-selection .icon {
  width: 0.375rem;
}
.payment .body .bank-selection .arrow-right {
  width: 0.15rem;
}
.payment .body .bank-selection ul {
  width: 100%;
  padding-left: 0.1rem;
}
.payment .body .bank-selection ul li:last-child {
  font-size: 0.1rem;
  color: grey;
}
.payment .list {
  background-color: #fff;
}
.payment .list .item {
  border-bottom-color: gainsboro;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 0.125rem;
}
.payment .red {
  color: darkred;
}

.face-recognition {
  padding: 0 0.15rem;
}
.face-recognition .face-button {
  display: block;
  line-height: 0.45rem;
  background: #bb1c22;
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
}
.face-recognition .successPrompt {
  height: 0.45rem;
  text-align: center;
  margin-top: 0.2rem;
}
.face-recognition .sucImg {
  display: inline-block;
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.1rem;
  vertical-align: middle;
  background: url("../../../images/mobile/icon_success.png") no-repeat;
  background-size: cover;
}

.sth-input-icon {
  width: -webkit-calc(100% - 0.5rem);
}

#sth-input-icon {
  width: -webkit-calc(100% - 0.3rem);
}

.input-ocr-icon {
  width: 0.5rem;
  height: 100%;
  background: url("../../../images/mobile/icon_camera.png") center no-repeat;
  position: absolute;
  top: 0;
  right: 0;
}

.photo-verifivation .upload {
  padding: 0.15rem;
}
.photo-verifivation .upload .upload-container {
  margin-bottom: 0.1rem;
  position: relative;
}
.photo-verifivation .upload .upload-container .delete {
  height: 0.2rem;
  width: 0.2rem;
  background: url("../../../images/mobile/icon_close.png") center no-repeat;
  background-size: 0.2rem;
  position: absolute;
  top: -0.1rem;
  right: -0.1rem;
  z-index: 49;
}
.photo-verifivation .upload .upload-container .img-container {
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  border: 0.005rem dashed #e2e2e2;
  background-color: #f2f2f2;
  color: #999999;
  position: relative;
  overflow: hidden;
}
.photo-verifivation .upload .upload-container .img-container img {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  object-fit: contain;
  margin: -1px;
  position: absolute;
  left: 0;
  top: 0;
}
.photo-verifivation .upload .upload-container .img-container input {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.photo-verifivation .upload .upload-container p {
  margin: 0;
  text-align: center;
  color: #999999;
  padding: 0.1rem 0;
}
