@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

#mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}

.pop-ease {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pop-ease.hidden {
  transform: translate3d(0, 100%, 0);
  -webkit-transform: translate3d(0, 100%, 0);
}

.pop-ease.shown {
  bottom: 7px;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.popup-alert {
  display: block;
  text-align: center;
  position: fixed;
  top: 35%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1400;
  max-width: 400px;
  width: 70%;
  font-size: 16px;
  background-color: white;
  border-radius: 4px;
}
.popup-alert .button {
  display: inline-block;
  width: 50%;
  font-size: 16px;
  line-height: 18px;
  height: auto;
  padding: 7.5px 12.5px;
  vertical-align: middle;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  background-color: #bb1c22;
  border-color: #bb1c22;
  color: #ffffff;
}

.popup-alert .alert-title {
  padding: 0 0.1rem;
  font-size: 0.125rem;
  text-align: left;
  color: #aaa;
  font-weight: normal;
}

.popup-alert .alert-content {
  min-height: 25px;
  padding: 0.1rem 0.1rem 0.3rem 0.1rem;
  font-size: 0.14rem;
  margin-left: 0;
  text-indent: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.popup-alert .alert-content div {
  text-align: left;
}

.popup-alert .alert-content p {
  margin: 0;
  line-height: 2;
}

.popup-alert .alert-btn {
  border-spacing: 8px;
  width: 100%;
  margin: 0 auto;
  border-collapse: separate;
}

.popup-alert .alert-btn .button {
  width: 100%;
  padding: 0;
  line-height: 2.8;
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.popup-alert .alert-btn .button-no {
  width: 50%;
  line-height: 2.8;
  color: #ffffff;
  background-color: #999999;
  border: none;
  border-bottom-left-radius: 4px;
}

.popup-alert .alert-btn .button-yes {
  width: 50%;
  line-height: 2.8;
  color: #ffffff;
  background-color: #bb1c22;
  border: none;
  border-bottom-right-radius: 4px;
}
