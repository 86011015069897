@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

.page {
  display: block;
  position: relative;
}

.scroll-touch {
  -webkit-overflow-scrolling: touch;
}

.env-mobile .page {
  position: absolute;
  height: 100vh;
  width: 100vw;
}
.env-mobile .page .page-header,
.env-mobile .page .page-footer {
  position: absolute;
}
.env-mobile .page .page-body {
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: scroll;
}

.page-header {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 44px;
  z-index: 49;
}

.page-body {
  display: block;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-top: 44px;
  padding-bottom: 0.48rem;
}

.page-footer {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 48px;
  line-height: 48px;
  z-index: 49;
}

html.env-desktop .page-header,
html.env-ipad .page-header {
  height: 0.44rem;
}
html.env-desktop .page-body,
html.env-ipad .page-body {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0.44rem;
  padding-bottom: 0.48rem;
  min-height: 100vh;
}
html.env-desktop .page-body .page-body-container,
html.env-ipad .page-body .page-body-container {
  display: block;
  position: relative;
}
html.env-desktop .page-body .page-body-container .page-body-location,
html.env-ipad .page-body .page-body-container .page-body-location {
  display: block;
  position: relative;
  padding: 5px 0;
  color: #666666;
  font-size: 0.16rem;
  font-weight: 500;
}
html.env-desktop .page-body .page-body-container .page-body-location:before,
html.env-ipad .page-body .page-body-container .page-body-location:before {
  content: "";
  display: table;
}
html.env-desktop .page-body .page-body-container .page-body-location:after,
html.env-ipad .page-body .page-body-container .page-body-location:after {
  content: "";
  display: table;
  clear: both;
}
html.env-desktop .page-body .page-body-container .page-body-location .path,
html.env-ipad .page-body .page-body-container .page-body-location .path {
  display: block;
  position: relative;
  float: left;
  margin-right: 8px;
  margin-left: 8px;
  cursor: pointer;
}
html.env-desktop .page-body .page-body-container .page-body-location .path:first-child,
html.env-ipad .page-body .page-body-container .page-body-location .path:first-child {
  margin-left: 0;
}
html.env-desktop .page-body .page-body-container .page-body-location .path:not(:first-child):before,
html.env-ipad .page-body .page-body-container .page-body-location .path:not(:first-child):before {
  content: "/";
  display: block;
  position: absolute;
  left: -10px;
  cursor: default;
}
html.env-desktop .page-footer,
html.env-ipad .page-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
