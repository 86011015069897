@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

.top-bar {
  display: block;
  position: relative;
  background-color: #bb1c22;
  height: 44px;
}
.top-bar:before {
  content: "";
  display: table;
}
.top-bar:after {
  content: "";
  display: table;
  clear: both;
}
.top-bar .left-btns,
.top-bar .right-btns {
  display: block;
  position: absolute;
  background-color: transparent;
  height: 44px;
  top: 0;
  padding-left: 0.15rem;
  z-index: 1;
}
.top-bar .left-btns:before,
.top-bar .right-btns:before {
  content: "";
  display: table;
}
.top-bar .left-btns:after,
.top-bar .right-btns:after {
  content: "";
  display: table;
  clear: both;
}
.top-bar .left-btns {
  left: 0;
}
.top-bar .right-btns {
  right: 0;
}
.top-bar .btn {
  display: block;
  position: relative;
  float: left;
  height: 0.44rem;
  line-height: 0.44rem;
  text-align: center;
  color: #ffffff;
  font-size: #ffffff;
  padding-right: 0.15rem;
}
.top-bar .btn span {
  display: block;
  position: relative;
  height: 44px;
  line-height: 44px;
  width: 100%;
}
.top-bar .back {
  font-size: 0.3rem;
}
.top-bar .back span {
  margin-top: -2px;
}
.top-bar .title {
  display: block;
  position: relative;
  float: left;
  font-size: 0.18rem;
  color: #ffffff;
  text-align: center;
  height: 44px;
  line-height: 44px;
  width: 100%;
}
