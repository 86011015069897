@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

.desk-bottom {
  display: block;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #efeae1;
  height: 48px;
  line-height: 48px;
}
.desk-bottom .copyright {
  display: block;
  position: relative;
}
