@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

#toast-container {
  z-index: 1;
}

.toast {
  display: block;
  position: fixed;
  top: 100%;
  left: 10%;
  width: 80%;
  opacity: 0.7;
  border-radius: 2px;
  color: #ffffff;
  background-color: black;
}
@media (min-width: 600px) {
  .toast {
    top: 0;
  }
}
@media (min-width: 1200px) {
  .toast {
    width: 600px;
    left: calc(50% - 300px);
  }
}
@media (min-width: 600px) {
  .toast {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.toast > div {
  display: block;
  position: relative;
  padding: 5px;
}
.toast > div.more-toast-items {
  font-size: 0.12rem;
  color: #f7ba2a;
  opacity: 1;
}
.toast > div.more-toast-items hr {
  display: none;
}
.toast > div hr {
  display: block;
  position: absolute;
  border: 0;
  border-bottom: 3px solid #ff4c29;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}
.toast > div.start hr {
  right: 100%;
  transition: right 1.7s linear 0.3s;
}
@media (min-width: 600px) {
  .toast > div.start hr {
    right: 100%;
    transition: right 2.7s linear 0.3s;
  }
}
.toast.toast-shown {
  top: calc(50% - 0.2rem / 2);
  transition: top 0.3s linear;
}
@media (min-width: 600px) {
  .toast.toast-shown {
    transition: top 0.3s linear;
  }
}
.toast .toast-icon {
  display: block;
  position: relative;
  line-height: 0.2rem;
  font-size: 1.2em;
  width: 0.3rem;
  float: left;
}
.toast .toast-icon.fa-question-circle {
  color: #b241d4;
}
.toast .toast-icon.fa-warning {
  color: #f7ba2a;
}
.toast .toast-icon.fa-info-circle {
  color: #50bfff;
}
.toast .toast-icon.fa-times-circle {
  color: #c22000;
}
.toast .toast-icon.fa-check-circle {
  color: #13ce66;
}
.toast .toast-icon.toast-icon-hidden {
  display: none;
}
.toast .toast-icon.toast-icon-hidden + .csms-toast-text {
  text-align: center;
  width: 100%;
}
.toast .toast-text {
  display: inline-block;
  position: relative;
  width: calc(100% - 0.2rem * 1.5);
}
