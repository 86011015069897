@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

#mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}

.pop-ease {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pop-ease.hidden {
  transform: translate3d(0, 100%, 0);
  -webkit-transform: translate3d(0, 100%, 0);
}

.pop-ease.shown {
  bottom: 7px;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.popup-alert {
  display: block;
  text-align: center;
  position: fixed;
  top: 35%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1400;
  max-width: 400px;
  width: 70%;
  font-size: 16px;
  background-color: white;
  border-radius: 4px;
}
.popup-alert .button {
  display: inline-block;
  width: 50%;
  margin-inline-start: 0;
  margin-inline-before: 0;
  font-size: 16px;
  line-height: 18px;
  height: auto;
  padding: 7.5px 12.5px;
  vertical-align: middle;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  background-color: #bb1c22;
  border-color: #bb1c22;
  color: #ffffff;
}

.popup-alert .alert-title {
  padding: 17px 20px 0;
  text-align: left;
  color: #aaa;
  font-weight: normal;
}

.popup-alert .alert-content {
  min-height: 25px;
  padding: 15px 20px 15px 22.5px;
  font-size: 0.14rem;
  margin-left: 1em;
  text-indent: -1em;
  display: -webkit-box;
  display: inline-block;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  -webkit-box-orient: vertical;
  overflow-y: scroll;
  max-height: 3rem;
}
.popup-alert .alert-content div {
  text-align: left;
}

.popup-alert .alert-content p {
  margin: 0;
  line-height: 2;
}

.popup-alert .alert-btn {
  border-spacing: 8px;
  width: 100%;
  margin: 0 auto;
  border-collapse: separate;
}

.popup-alert .alert-btn .button {
  width: 100%;
  padding: 0;
  line-height: 2.8;
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.popup-alert .alert-btn .button-no {
  display: inline-block;
  width: 50%;
  line-height: 2.8;
  color: #ffffff;
  background-color: #999999;
  border: none;
  border-bottom-left-radius: 4px;
}

.popup-alert .alert-btn .button-yes {
  display: inline-block;
  width: 50%;
  line-height: 2.8;
  color: #ffffff;
  background-color: #bb1c22;
  border: none;
  border-bottom-right-radius: 4px;
}

.smallSize {
  font-size: 13px;
}

.hasImg-style .popup-alert {
  width: calc(100% - 1rem);
  top: 25%;
  border-radius: 0.1rem;
}
.hasImg-style .popup-alert img {
  width: 1.735rem;
  margin-top: 0.125rem;
}
.hasImg-style .popup-alert .alert-title {
  text-align: center;
  font-size: 0.16rem;
  font-weight: 600;
  color: rgb(38, 38, 38);
  line-height: 0.215rem;
  margin-bottom: 0;
}
.hasImg-style .popup-alert .alert-content {
  padding-top: 0.13rem;
  padding-left: 0.195rem;
  padding-right: 0.195rem;
  padding-bottom: 0.17rem;
}
.hasImg-style .popup-alert .alert-content div p {
  font-size: 0.12rem;
  font-weight: 400;
  color: rgb(38, 38, 38);
  line-height: 0.17rem;
}
.hasImg-style .popup-alert .alert-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.22rem;
  padding-left: 0.255rem;
  padding-right: 0.255rem;
}
.hasImg-style .popup-alert .alert-btn .button-no {
  width: 1rem;
  line-height: 0.33rem;
  border: 1px solid rgb(153, 153, 153);
  border-radius: 0.165rem;
  background: white;
  color: #999999;
  font-size: 14px;
}
.hasImg-style .popup-alert .alert-btn .button-yes {
  width: 1rem;
  line-height: 0.33rem;
  border-radius: 0.165rem;
  background: #e43942;
  color: white;
  font-size: 14px;
}
