@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
@import '~font-awesome/css/font-awesome.css';
.dplayer-mobile-play {
  background: #000;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 100px;
  width: 100%;
  min-height: 100%;
}

body {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 0.14rem;
  color: #333333;
  background: #eeeeee;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Microsoft YaHei";
  line-height: 0.2rem;
  background-color: #f1f1f1;
  overflow: hidden;
  overflow-y: auto;
}
@media (max-width: 599px) {
  body {
    -webkit-user-select: none;
    user-select: none;
  }
}

#root {
  display: block;
  position: relative;
}

input,
select {
  line-height: 0.2rem;
  height: 44px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 0.14rem;
}

select {
  cursor: pointer;
}

input[disabled],
select[disabled] {
  opacity: 0.7;
  cursor: default;
}
input[disabled].label-like,
select[disabled].label-like {
  opacity: 1;
}

input[type=text],
input[type=password],
input[type=number],
input[type=tel],
input[type=email],
input[type=search],
input[type=url],
input[type=checkbox],
input[type=radio],
input[type=date],
select,
textarea {
  display: block;
  border: none;
  border-radius: 0;
  outline: none;
  margin: 0;
  color: #363636;
  background: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
}

a {
  color: #363636;
  line-height: 0.2rem;
  cursor: pointer;
}

a,
a:link,
a:visited,
a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

a.form-btn,
a.form-btn-transparent {
  display: block;
  position: relative;
  text-align: center;
  height: 44px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
}

a.form-btn-transparent {
  font-size: 0.16rem;
}

a.form-btn {
  color: #ffffff;
  font-size: 0.18rem;
  font-weight: 603;
  padding-left: 16px;
  padding-right: 16px;
  background: linear-gradient(90deg, #34c2f5 0, #34c2f5 42%, #87cefa 100%, #87cefa 100%);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

html.csms-lang-zh a.form-btn.gap,
html.csms-lang-zh a.form-btn-transparent.gap,
html.csms-lang-zh-cn a.form-btn.gap,
html.csms-lang-zh-cn a.form-btn-transparent.gap,
html.csms-lang-zh-tw a.form-btn.gap,
html.csms-lang-zh-tw a.form-btn-transparent.gap {
  letter-spacing: 17px;
  padding-left: 32px;
}

html.env-mobile input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

div.slide-left-enter, div.slide-left-enter .page-header, div.slide-left-enter .page-footer {
  left: 100%;
}
div.slide-left-enter .page-header, div.slide-left-enter .page-footer {
  right: -100%;
}
div.slide-left-enter.slide-left-enter-active, div.slide-left-enter.slide-left-enter-active .page-header, div.slide-left-enter.slide-left-enter-active .page-footer {
  left: 0;
  transition: left 400ms linear;
}
div.slide-left-enter.slide-left-enter-active .page-header, div.slide-left-enter.slide-left-enter-active .page-footer {
  right: 0;
  transition: left 400ms linear, right 400ms linear;
}
div.slide-left-leave, div.slide-left-leave .page-header, div.slide-left-leave .page-footer {
  left: 0;
}
div.slide-left-leave .page-header, div.slide-left-leave .page-footer {
  right: 0;
}
div.slide-left-leave.slide-left-leave-active, div.slide-left-leave.slide-left-leave-active .page-header, div.slide-left-leave.slide-left-leave-active .page-footer {
  left: -100%;
  transition: left 400ms linear;
}
div.slide-left-leave.slide-left-leave-active .page-header, div.slide-left-leave.slide-left-leave-active .page-footer {
  right: 100%;
  transition: left 400ms linear, right 400ms linear;
}
div.slide-right-enter, div.slide-right-enter .page-header, div.slide-right-enter .page-footer {
  left: -100%;
}
div.slide-right-enter .page-header, div.slide-right-enter .page-footer {
  right: 100%;
}
div.slide-right-enter.slide-right-enter-active, div.slide-right-enter.slide-right-enter-active .page-header, div.slide-right-enter.slide-right-enter-active .page-footer {
  left: 0;
  transition: left 400ms linear;
}
div.slide-right-enter.slide-right-enter-active .page-header, div.slide-right-enter.slide-right-enter-active .page-footer {
  right: 0;
  transition: left 400ms linear, right 400ms linear;
}
div.slide-right-leave, div.slide-right-leave .page-header, div.slide-right-leave .page-footer {
  left: 0;
}
div.slide-right-leave .page-header, div.slide-right-leave .page-footer {
  right: 0;
}
div.slide-right-leave.slide-right-leave-active, div.slide-right-leave.slide-right-leave-active .page-header, div.slide-right-leave.slide-right-leave-active .page-footer {
  left: 100%;
  transition: left 400ms linear;
}
div.slide-right-leave.slide-right-leave-active .page-header, div.slide-right-leave.slide-right-leave-active .page-footer {
  right: -100%;
  transition: left 400ms linear, right 400ms linear;
}
div[class*=slide-] {
  position: absolute;
  width: 100vw;
}
div[class*=slide-] .page-body {
  height: calc(100vh - 44px - 48px);
}
div[class*=slide-].me-quote-list .page-body {
  height: calc(
          100vh - 44px * 2 - 48px
        );
}

/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

html.env-desktop,
html.env-ipad {
  overflow-y: scroll;
}
html.env-desktop input,
html.env-desktop select,
html.env-ipad input,
html.env-ipad select {
  line-height: 0.2rem;
  height: 32px;
  padding-top: 6px;
  padding-bottom: 6px;
}
html.env-desktop div.validate.fail:after, html.env-desktop div.validate.success:after, html.env-desktop div.validate:after,
html.env-ipad div.validate.fail:after,
html.env-ipad div.validate.success:after,
html.env-ipad div.validate:after {
  line-height: 32px;
}
html.env-desktop div.validate.password > span.pwd-btn,
html.env-ipad div.validate.password > span.pwd-btn {
  color: #006596;
}

.desk-form {
  display: block;
  position: relative;
  border: 1px solid #006596;
  border-radius: 4px;
}
.desk-form.no-border {
  border: 0;
}
.desk-form .form-body,
.desk-form .form-footer,
.desk-form .form-header {
  display: block;
  position: relative;
}
.desk-form .form-body:before,
.desk-form .form-footer:before,
.desk-form .form-header:before {
  content: "";
  display: table;
}
.desk-form .form-body:after,
.desk-form .form-footer:after,
.desk-form .form-header:after {
  content: "";
  display: table;
  clear: both;
}
.desk-form .form-header {
  background-color: #006596;
  color: #ffffff;
  padding: 0 10px;
  line-height: 44px;
  height: 44px;
}
.desk-form .form-header > span {
  display: block;
  position: relative;
  height: 44px;
  line-height: 44px;
  font-size: 0.18rem;
}
.desk-form .form-body {
  padding: 5px 10px;
}
.desk-form .form-footer {
  padding: 0 10px;
}
.desk-form .form-footer .form-row:before {
  content: "";
  display: table;
}
.desk-form .form-footer .form-row:after {
  content: "";
  display: table;
  clear: both;
}
.desk-form .form-footer .form-row .pagination {
  float: left;
}
.desk-form .form-footer .form-row .pagination + .form-button {
  float: right;
  width: auto;
}
.desk-form .form-footer .form-row .form-button a.form-btn {
  min-width: 120px;
}
.desk-form .form-row {
  display: block;
  position: relative;
  line-height: 0.2rem;
  float: left;
  width: 100%;
}
.desk-form .form-row .form-cell,
.desk-form .form-row .form-conj-label,
.desk-form .form-row .form-input,
.desk-form .form-row .form-label {
  display: block;
  position: relative;
  float: left;
  line-height: 0.2rem;
  padding-top: 6px;
  padding-bottom: 6px;
}
.desk-form .form-row .form-cell > span,
.desk-form .form-row .form-conj-label > span,
.desk-form .form-row .form-input > span,
.desk-form .form-row .form-label > span {
  display: block;
  position: relative;
  line-height: 0.2rem;
  height: 32px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.desk-form .form-row .form-cell > div,
.desk-form .form-row .form-conj-label > div,
.desk-form .form-row .form-input > div,
.desk-form .form-row .form-label > div {
  width: 100%;
}
.desk-form .form-row .form-input > span,
.desk-form .form-row .form-label > span {
  border-bottom: 1px solid #d9d9d9;
}
.desk-form .form-row .form-input > div.validate:before,
.desk-form .form-row .form-label > div.validate:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 31px;
  right: 0;
  height: 1px;
  border-bottom: 1px solid #006596;
  z-index: 1;
}
.desk-form .form-row .form-input > div.validate.disabled:before,
.desk-form .form-row .form-label > div.validate.disabled:before {
  border-color: #d9d9d9;
}
.desk-form .form-row .form-input > div.validate.fail:before,
.desk-form .form-row .form-label > div.validate.fail:before {
  border-color: #c22000;
}
.desk-form .form-row .form-conj-label > span {
  border-bottom: 1px solid #006596;
}
.desk-form .form-row .form-label {
  width: 30%;
}
.desk-form .form-row .form-input {
  width: 70%;
}
.desk-form .form-row .form-button {
  display: block;
  position: relative;
  float: left;
  height: 44px;
  line-height: 0.2rem;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
}
.desk-form .form-row a.form-btn {
  display: block;
  position: relative;
  float: right;
  font-size: 0.16rem;
  height: 32px;
  line-height: 32px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 2px;
  background: none;
  margin-left: 5px;
}
.desk-form .form-row a.form-btn.primary {
  background-color: #006596;
}
.desk-form .form-row a.form-btn.success {
  background-color: #13ce66;
}
.desk-form .form-row a.form-btn.danger {
  background-color: #c22000;
}
.desk-form .form-row a.form-btn.info {
  background-color: #50bfff;
}
.desk-form .form-row a.form-btn.warn {
  background-color: #f7ba2a;
}
.desk-form .form-row a.form-btn.question {
  background-color: #b241d4;
}
.desk-form .form-row a.form-btn.waive {
  background-color: #919191;
}
.desk-form .form-row a.form-btn.left {
  float: left;
  margin-left: 0;
  margin-right: 5px;
}
.desk-form .form-row.header .form-cell {
  border-bottom: 2px solid #cccccc;
  font-weight: 500;
}
.desk-form .form-row.body.even:hover, .desk-form .form-row.body.odd:hover, .desk-form .form-row.body:hover {
  background-color: #e6e6e6;
}
.desk-form .form-row.body.even {
  background-color: #f2f2f2;
}
.desk-form .form-row.body .form-cell.grab-all {
  width: 100%;
}
.desk-form .form-row.body .form-cell.no-data {
  text-align: center;
  opacity: 0.7;
}
.desk-form .form-row.double-row .form-label > span {
  height: 76px;
}
.desk-form .form-row.double-row .form-input .validate:last-child {
  margin-top: 12px;
}
.desk-form .form-row.range-row .form-conj-label {
  width: 40px;
  text-align: center;
}
.desk-form .form-row.range-row .form-input {
  width: calc((70% - 40px) / 2);
}
.desk-form.single-column {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.desk-form.two-cols {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.desk-form.two-cols .form-row {
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;
}
.desk-form.two-cols .form-row.grab-row {
  width: 100%;
}
.desk-form.two-cols .form-row.wrap-row + .form-row {
  clear: both;
}
.desk-form.four-cols {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.desk-form.four-cols .form-row {
  width: 25%;
}
.desk-form.four-cols .form-row.grab-row {
  width: 100%;
}
.desk-form.max {
  min-width: 1200px;
  width: calc(100vw - 30px);
  margin-left: auto;
  margin-right: auto;
}
.desk-form .form-row.new-row {
  clear: both;
}
.desk-form.query-form .form-row, .desk-form.two-cols.query-form .form-row {
  width: 100%;
}
.desk-form.query-form .form-body .form-row:last-child, .desk-form.two-cols.query-form .form-body .form-row:last-child {
  border-bottom: 2px solid #cccccc;
}
