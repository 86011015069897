@charset "UTF-8";
/*桌面端*/
/*移动端*/
/*something*/
.dplayer-mobile-play {
  background: #000;
}

.policy-list {
  display: block;
  position: relative;
  background-color: transparent;
  margin-bottom: 10px;
}

.policy {
  display: block;
  position: relative;
  background-color: #ffffff;
  padding: 0.1rem;
  padding-top: 0;
}
.policy:before {
  content: "";
  display: table;
}
.policy:after {
  content: "";
  display: table;
  clear: both;
}
.policy:not(:last-child) {
  margin-bottom: 4px;
}
.policy .content {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  font-size: 0.14rem;
}
.policy .content:before {
  content: "";
  display: table;
}
.policy .content:after {
  content: "";
  display: table;
  clear: both;
}
.policy .content .title {
  overflow: hidden;
  height: 0.45rem;
}
.policy .content .title .icon-policy {
  display: block;
  height: 0.45rem;
  width: 0.45rem;
  float: left;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url("../../../images/mobile/icon_policy.png");
}
.policy .content .title .indicator {
  color: #bb1c22;
  display: block;
  position: relative;
  float: left;
  width: 25%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 0.45rem;
  line-height: 0.45rem;
}
.policy .content .title .label-2 {
  color: #999999;
  display: block;
  position: relative;
  float: left;
  width: 20%;
  height: 0.45rem;
  line-height: 0.45rem;
}
.policy .content .title .text-2 {
  color: #515151;
  display: block;
  position: relative;
  float: left;
  width: 40%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 0.45rem;
  line-height: 0.45rem;
}
.policy .content .main {
  background: #f7f7f7;
  padding: 0.1rem;
}
.policy .content .main .item {
  overflow: hidden;
  height: 0.3rem;
  line-height: 0.3rem;
}
.policy .content .label {
  color: #999999;
  display: block;
  position: relative;
  float: left;
  width: 25%;
  height: 0.3rem;
  line-height: 0.3rem;
}
.policy .content .text {
  color: #515151;
  display: block;
  position: relative;
  float: left;
  width: 75%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 0.3rem;
  line-height: 0.3rem;
}
.policy .content .label-2 {
  color: #999999;
  display: block;
  position: relative;
  float: left;
  width: 25%;
  height: 0.3rem;
  line-height: 0.3rem;
}
.policy .content .text-2 {
  color: #515151;
  display: block;
  position: relative;
  float: left;
  width: 25%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 0.3rem;
  line-height: 0.3rem;
}
.policy .content .right {
  text-align: right;
}
.policy .content .red {
  color: #bb1c22;
}
