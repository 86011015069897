/*cssId:BASE*/
.custom-breadCrumb {
  background: white;
  padding: 0.22rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 0.09rem;
}
.custom-breadCrumb .bread_item {
  position: relative;
  width: 0.81rem;
}
.custom-breadCrumb .bread_item:not(:last-child)::after {
  content: "";
  display: block;
  width: 0.25rem;
  height: 0.02rem;
  background: rgb(214, 214, 214);
  position: absolute;
  top: 0.145rem;
  right: 0;
}
.custom-breadCrumb .bread_item:not(:first-child)::before {
  content: "";
  display: block;
  width: 0.25rem;
  height: 0.02rem;
  background: rgb(214, 214, 214);
  position: absolute;
  top: 0.145rem;
  left: 0;
}
.custom-breadCrumb .bread_item .title {
  text-align: center;
}
.custom-breadCrumb .bread_item .cc {
  font-size: 0.15rem;
  font-weight: bold;
  color: rgb(214, 214, 214);
  margin: 0 auto;
  margin-bottom: 0.1rem;
  text-align: center;
  width: 0.31rem;
  height: 0.31rem;
  border: 0.025rem solid rgb(214, 214, 214);
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.custom-breadCrumb .bread_item.active .title {
  color: #e43942;
}
.custom-breadCrumb .bread_item.active .cc {
  color: #e43942;
  border-color: #e43942;
}
.custom-breadCrumb .bread_item.active::after {
  background: #e43942;
}
.custom-breadCrumb .bread_item.active::before {
  background: #e43942;
}
.custom-breadCrumb::after {
  display: block;
  height: 0.09rem;
  background: #f7f7f7;
  width: 100%;
  content: "";
  border-top: 1px solid #e3e3e3;
  position: absolute;
  left: 0;
  bottom: -0.09rem;
}
