#tmp_canvas {
  position: absolute;
  left: 0px;
  right: 100%;
  top: 0px;
  cursor: crosshair;
}

.orange {
  color: #ffffff;
  background: #d54343;
}

#dialog .button {
  border: none;
  -webkit-appearance: none;
  zoom: 1; /* zoom and *display = ie7 hack for display:inline-block */
  margin: 0 2px !important;
  text-align: center;
  padding: 0.25em 1em 0.25em !important;
  font-size: 13px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

#mesWindow {
  z-index: 8;
  -webkit-border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  border-radius: 0.5em;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 0.5em;
  width: 80%;
  position: absolute;
  left: 10%;
  top: 30%;
}

#mesWindowTop {
  background-color: rgba(147, 152, 168, 0.2);
  border-bottom: #eee 1px solid;
  margin-left: 4px;
  padding: 3px;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
}

#mesWindowContent {
  background-color: rgba(147, 152, 168, 0.2);
  border-bottom: #d6d6d6 1px solid;
  text-align: center;
  font-size: 12px;
  padding: 60px 20px 60px 20px;
}

#mesWindow input {
  text-align: center;
  font-size: 13px;
}

#mesWindowBottom {
  background-color: rgba(147, 152, 168, 0.2);
  text-align: center;
  padding: 10px 10px 10px 10px;
}
