.search-wrap.select .searchClick {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.search-wrap.select .search-box-window .open > ul {
  display: block;
}
.search-wrap.select .search-box-window .open > li:first-child {
  padding: 0.15rem;
  padding: 0.15rem;
  background-image: url("../../../images/wechat/arrow_down_active.png");
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: 0.1rem auto;
}
.search-wrap.select .search-box-window .close > ul {
  display: none;
}
.search-wrap.select .search-box-window .close > li:first-child {
  padding: 0.15rem;
  background-image: url("../../../images/mobile/arrow_down.png");
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: 0.1rem auto;
}
.search-wrap.select .search-box-window .selectList {
  padding-bottom: 1rem;
  height: calc(100% - 0.75rem);
  overflow: scroll;
}
.search-wrap.select .search-box-window .selectList li {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}
.search-wrap.select .search-box-window .selectList .lev1 {
  padding: 0 0.15rem;
}
.search-wrap.select .search-box-window .selectList .lev1 > li {
  border-bottom: 1px solid #dbdbdb;
}
.search-wrap.select .search-box-window .selectList .lev1 .lev2 > li {
  border-bottom: 1px dashed #dbdbdb;
  padding-left: 0.3rem;
}
.search-wrap.select .search-box-window .selectList .lev1 .lev2.open > li:first-child {
  background-position: 0.15rem center;
}
.search-wrap.select .search-box-window .selectList .lev1 .lev2.close > li:first-child {
  background-position: 0.15rem center;
}
.search-wrap.select .search-box-window .selectList .lev1 .lev2 .lev3 > li {
  border-bottom: 1px dashed #dbdbdb;
  padding-left: 0.45rem;
}
.search-wrap.select .search-box-window .selectList .lev1 .lev2 .lev3.open > li:first-child {
  background-position: 0.3rem center;
}
.search-wrap.select .search-box-window .selectList .lev1 .lev2 .lev3.close > li:first-child {
  background-position: 0.3rem center;
}
.search-wrap.select .search-box-window .selectList .lev1 .lev2 .lev3 .lev4 > li {
  border-bottom: 1px dashed #dbdbdb;
  padding-left: 0.6rem;
}
